@import '../config/variables';

.text-primary {
	color: #348a0c !important;
}
.dashboard-card {
	height: 100%;
	width: 100%;
	.activity-card {
		background: #ffffff;
		border-radius: 5px;
		display: flex;
		align-items: center;
		h5 {
			font-size: 0.75rem;
			color: $blue;
			font-weight: 600;
		}
		.user-progress {
			margin-bottom: -1rem;
		}
	}
	.activity-card.not-loading {
		&:hover {
			background-color: $user-dashboard-widgets-bg !important;
		}
	}
}

.ud-project-list{
	thead{
		background-color: #fff !important;
	}
}

.number {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 600;
	font-size: 1.375rem;
	color: $konark-primary-color;
}
.i-logo {
	position: absolute;
	right: 0;
	margin-right: 7px;
	font-size: 1rem;
	color: #615d5d;
	top: 0;
}
.card-text {
	font-size: 0.625rem;
	font-weight: 400;
}
.active-projects {
	background: rgba(236, 185, 7, 0.24);
	border-radius: 5px;
	padding-bottom: 9px;
	padding-left: 13px;
	padding-right: 13px;
	padding-top: 7px;
}
.most-used-app {
	background: rgba(162, 223, 206, 0.53);
	border-radius: 5px;
	padding-bottom: 9px;
	padding-left: 13px;
	padding-right: 13px;
	padding-top: 7px;
}

.project-overview {
	height: 100%;
	width: 100%;

	h5 {
		color: #615D5D;
		font-family: Open Sans;
		font-size: 1rem;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
	.np-count {
		color: #000;
		font-size: 1.5rem;
		font-family: Open Sans;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
	.activity-card {
		background: #ffffff;
		border: 0.5px solid #f5f5f5;
		border-radius: 5px;
	}
	h4 {
		margin-bottom: 1.5rem;
		font-size: 20px;
		color: $blue;
	}
	.card-col {
		border-radius: 5px;
		background-color: #f5f5f5 !important;
		padding: 0.6rem;
		.text-success {
			color: #348a0c !important;
		}
		.text-danger {
			color: #d71509 !important;
		}
	}
	.rounded {
		border-radius: 50% !important;
		background-color: #daeefa;
		.avatar-text {
			font-size: 1rem !important;
			padding: 5px;
			color: #000 !important;
		}
	}
}

.daily-goal {
	min-height: 100%;
	background: #ffffff;
	border: 0.5px solid #f5f5f5;
	border-radius: 5px;
	margin-bottom: 6px;
	font-size: 1rem;
	font-weight: 600;

	.arrow {
		border: none;
		font-size: 1.3rem;
		padding: 0.2rem;
	}
	.header-date {
		color: $konark-primary-color;
		text-align: center;
		font-size: 1rem;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
	.feedback-text {
		font-size: 1rem;
		font-weight: 400;
	}

	.CircularProgressbar-text{
		color: #000;
		text-align: center;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}

	.dg-font{
		font-weight: 400;
		font-size: 1rem;
	}

	.dg-time{
		font-weight: 600;
		font-size: 20px;
	}

	.progress-warning {
		.CircularProgressbar-text {
			fill: $konark-warning-color !important;
		}
		.CircularProgressbar-path {
			stroke: $konark-warning-color !important;
		}
	}
	.progress-danger {
		.CircularProgressbar-text {
			fill: $konark-danger-color !important;
		}
		.CircularProgressbar-path {
			stroke: $konark-danger-color !important;
		}
	}
	.progress-success {
		.CircularProgressbar-text {
			fill: $konark-success-color !important;
		}
		.CircularProgressbar-path {
			stroke: $konark-success-color !important;
		}
	}
}
.project-header {
	text-align: left !important;
	background-color: #f5f5f5 !important;
	padding: 0.5rem 1rem;

	.card-title{
		font-size: 1.25em;
		font-weight: 600;
	}
	.right-section {
		font-size: 0.9rem;
	}

	.search-box{
		background-color: #fff;
		border: none;
	}
}

.projects-graph {
	height: 100%;
	width: 100%;
	border: 0.5px solid #f5f5f5;
	.graph-header {
		font-size: 1.3rem;
		font-weight: 600;
	}
}


.mobile-styles{
	.project-header{
		background-color: #fff !important;
	}
}