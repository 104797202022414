.screenshot-hour-row {
    padding: 1rem;
}
.datepicker-icon{
    width: 1.3rem;
    height: 1rem;
    margin-top: 0.5rem !important;
    img {
        width: 100%;
    } 
}
.screenshot-hour {
    font-size: 1rem;
    color:#28397F;
    font-weight: 600;
}

.screenshot-container {
    margin-top: 0.5rem;
}
.screenshot-container-header {
    border-bottom: 1px solid #DFDEDE;
    border-top: 1px solid #DFDEDE;
    padding: 0.7rem;
}