@import '../config/variables';

select::placeholder {
	text-align: left;
}
.message a {
	text-decoration: none;
}





.acitivity-item.py-3.d-flex:before {
	content: '';
	position: absolute;
	border-left: 1px solid #d9d9d9;
	left: 28px;
	height: calc(100% - 10px);
	top: 5px;
	z-index: 0;
}
.acitivity-item.py-3,
.acitivity-timeline.py-3 {
	padding-top: 0px !important;
}

.dot-icon {
	font-size: 27px;
	color: $konark-primary-color;
	z-index: 2;
}
.tagline-text {
	font-size: 12px;
	font-size: 600;
	color: #615d5d;
}
.style-align {
	text-align: left;
}
.flatpicker-border {
	border: 0.5px solid #d9d9d9;
}
.list-unstyled a {
	text-decoration: none;
}
.activity-log-card {
	border: 0.5px solid #d9d9d9;
	border-radius: 10px;
	.card-body{
		border-radius: 10px;
		height: 100%;
		width: 100%;
	}
}

.activity-card-report-section {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.filter-button{
	visibility: hidden;
	@media (max-width: 991px) {
		visibility: visible;
	}
	width:5rem;
	margin-right: 0rem;
}

.add-timelog {
	margin-left: 0.5rem;
	font-size: 0.9rem;
	text-align: center;
}

.filter-modal {
	visibility: hidden;
	@media (max-width: 991px) {
		visibility: visible;
	}
}
@media (max-width: 991px) {
	.activity-log-filter-card{
		display: none;
	}
}

.filter-desktop-view {
	visibility: hidden;
}
