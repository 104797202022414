@import '../config/variables';

.profile-card {
    background-color: rgba(245,220,130, 0.5) !important;
    border-radius: 2%;
    border: 0.5px solid #C5C5C5;
}
.vc-img {
    background-color: $alternate-background-color-mauve;
    display: flex;
    justify-content: center;
    align-items: center;
}
.card-border {
    border-radius: 4px;
    border: 0.5px solid #C5C5C5;
    color: #615D5D;
    .card-header{
        text-align: left !important;
        font-size: small;
    }
    
}

.card-body {
    h6 {
        color: $blue
    }
    h1{
        font-size: 5rem;
    }
}
.card-header.card-header-border{
    border-bottom: 0.5px solid #C5C5C5;
}
.card-body.card-body-border {
    border-top: 0.5px solid #C5C5C5;
}

.project-header {
    .row{
        margin-left: 1px;
    }
    &.card-header {
        padding: 0px;
    }
    .nav-link {
        padding: 1.25rem;
        &.active {
            background-color: #F6FBFF !important;
			color: $konark-primary-color !important;
        }
    }
}
.project-body{
    &.card-body {
        padding: 0;
    }
}

.termscondition{
    .colors{
            background-color: #0FBEC9;
            color: white;
        }
    
    .save-button{
    margin-left: 20px;
    }
    .formfields{
        textarea{
            border: 0.5px solid #B8B8B8;
        }
    }
    
}
.save-button-project{
    padding: 0.5rem;
    margin-left: 0.5rem;
    font-size: 0.8rem;
}