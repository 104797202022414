@import '../config/variables';


.setting-card{
    height: 100%;
    margin-bottom: 1.5rem;

    .main-card{
        margin-left: 2px; 
        border: 0.5px solid #28397F; 
        border-radius: 10px; 
        padding-left: 0px; 
        padding-right: 0px ;
    }
}
.nav-link {
    color: #28397F;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
  }
.setting-nav-card{
    height: 100%;
    .nav-link.active {
        background-color: #F6FBFF;
        color: #0FBEC9;
        border-left:3px solid #0FBEC9;
        border-radius: 0px;
        border-left-width: thick;
    }
    i.ri-arrow-left-s-line, i.ri-arrow-right-s-line{
        visibility: hidden;
    }

    @media (max-width: 767px) {
        height: unset;
        .card{
            height: unset;
        }
        ul{
            display: flex;
            flex-direction: row !important;
            flex-wrap: nowrap;
            overflow-x: auto;
            overflow: hidden;
            white-space: nowrap;

        }
        .nav-link {
            float: left;
          }
          .nav-link.active {
            background-color: #0FBEC9;
            color: white;
            border-radius: 10px;
            border-left:none;
            border-left-width: none;
        }
        button{
            position: absolute;
            padding-left: 0px;
            padding-right: 0px;
            border-radius: 10px;
            top: 0;
            height: 100%;
            font-size: large;
            color: white;
            background-color: #28397F;

        }
        button.swipe-left{
            left: 0;
        }
        button.swipe-right{
            right: 0;
        }
        i.ri-arrow-left-s-line, i.ri-arrow-right-s-line{
            visibility: visible;
        }
        
    }
}
.multiple-field-form {
    padding: 0 6rem;
    padding-left: 0px;
    // position: relative;
    top: 6rem;
    .multiple-file-field {
        border: $file-field-border;
        border-radius: 4px;
        padding: 0;
        .multiple-file-field-label {
            background-color: $auth-page-input-color;
            font-size: 1rem;
            font-weight: 500;
            width: 30%;
            padding-bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .multiple-file-field-input {
            border-bottom: none;
            &::-webkit-file-upload-button {
                visibility: hidden;
                color: transparent;
            }
        }
    }
    label {
        color: $konark-primary-color;
        font-weight: 600;
        font-size: 1.2rem;
    }
    .button-container {
        display: flex;
        width: 100%;
        justify-content: center;
    }
}

.custom-file-label-setting {
	position: absolute;
	top: 0;
	z-index: 1;
	height: calc(2.0625rem + 2px);
	padding: 0.375rem 0.75rem;
	line-height: 1.5;
	border-radius: 2px;
	font-size: 12px !important;
	font-weight: 400 !important;
	margin-top: 186px;
}

.half-rectangle{
    display:inline-block;
    border-top: 70px solid transparent;
    opacity: 70%;
    border-right: 70px solid #000000;
    margin-left: 30px;
    margin-top: 30px;
    .camera-icon{
        position: absolute;
        padding-left: 2.7rem;
        font-size: 1.5rem;
        opacity: 100%;
        margin-top: -23px;
    }
}

.image{
    height: 100px;
    width: 100px;
    border: 1px solid black;
    border-radius: 1px;
    position: absolute;
}
.title{
    font-size: 18px; 
    display: flex;
    font-weight: 600;
    align-items: center
}
.card-padding{
    border-radius: 20px;
    padding-left: 30px 
}
.nav-card-header{
    left: 100px;
    border-bottom: 0.5px solid #28397F;
    padding-top: 5px;
    padding-bottom: 2px;
}

@media (max-width: 768px) {
    .nav-card-header {
        display: flex;
        flex-direction: column; /* Stack elements vertically */
        align-items: center; /* Align items to the left */
        &.screenshot-card {
            flex-direction: column-reverse;
            .title{
                align-self: start;
            }
            span{
                align-self: end;
            }
        }
    }
    .screenshot{
        padding-right: 10px !important;
    }
    .setting-card{
        margin-top: 0.5rem;
    }
}

.blockquote{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #615D5D;
}
  
.heading-div{
    line-height: 19px;
    font-size: 16px;
    font-weight:500 ;
    align-items: center;
    color: #28397F;
    display: flex;
    padding-left: 20px ;
}
.heading-div{
    .screenshot{
        padding-right: 10px;
        margin-bottom: 2.5px;
    }
}
.timesheet{
    .heading-div{
        width: 290px;
        justify-content: space-between;
        padding-top: 20px;
    }
}

.checkbox-tag{
    font-size: 18px;
    background-color: #28397F;
    margin-top: 0px;
}

.nav-tab{
    height: 100%;
    width: 100%;
    padding: 10px 0px 10px 0px;
    border: 0.5px solid #28397F;
    background-color: #F6FBFF !important;
    border-radius: 10px;
}
.tagline{
    color: #615D5D;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    display: flex;
    align-items: center;
    padding-top: 22px;
    padding-bottom: 22px
}
.formfields{
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #28397F;
    .ri-delete-bin-line {
        font-size: 1.5rem;
        font-weight: 100;
        color: $konark-danger-color;
        cursor: pointer;
    }
    input{
        border: 0.5px solid #C5C5C5;
        border-radius: 5px; 
    }
}
.formfields:hover input{
    border: 1px solid #28397F;
}
.formfields input:not(:placeholder-shown) {
    border:1px solid #28397F
  }

.timesheet{
    .save-button{
        margin: 57px 80px 69px 17px;
    }
}
.timezone{
    .save-button{
        margin-left: 20px;
    }
}
.termscondition{
    .colors{
        background-color: #0FBEC9;
        color: white;
    }
    
    .save-button{
        margin-left: 20px;
    }
    .formfields{
        textarea{
            border: 0.5px solid #B8B8B8;
        }
    }
    
}

.tags{
    .save-button{
        margin-left: 20px;
    }
    .apps-block{
        font-size: 12px;
        background-color: #0FBEC9;
        color: white;
        border-radius: 50px;
        display: inline-block;
        font-weight:600;
        }
    .card-table{
        padding: 40px 0px 0px 0px;
    }
    .table-view{
        border: 0.5px solid #C5C5C5;
        border-radius: 10px;
    }
    .nav-card{
        padding-top: 0px;
    }
    .card-padding{
        padding-top: 0px;
    }
    .apps-block:hover{
        border: 0.5px solid #D71509;
        span {
        display: inline-block;
      }}
      span {
        display: none;
      }
      span i {
        color: #D71509;
        font-size: 12px;
      }
}
.react-modal-select__control {
    overflow: auto;
    max-height: 4rem;
}
.react-modal-select__placeholder {
    font-size: 1rem;
}
.react-datepicker-wrapper {
    width: 100%;
}
.react-modal-select__menu {
    background-color: #ffffff !important;
    .react-modal-select__menu-list {
        border-bottom: 0.2px solid $konark-secondary-color;
        .react-modal-select__option {
            &:hover {
                background-color: $select-option-bg-color;
            }
        }
    }
}