@import '../config/variables';

.company-profile-input{
    input,textarea{
        font-size: 1.2rem;
        padding: 0;
        font-weight: 500;
        resize: none;
    }
}

.profile-card {
    background-color: rgba(245,220,130, 0.5) !important;
    border-radius: 2%;
    border: 0.5px solid #C5C5C5;
}
.svg-bg {
    background-color: rgba(255, 240, 188, 0.5) !important;
}
.active {
    input, textarea {
        border: 0.5px solid #C4C4C4 !important;
    }
    ::placeholder {
        opacity: 1;
    }
}
input, textarea {
    border: none;
}
::placeholder {
    opacity: 0;
}
.cp-text {
    color: $blue;
}
.cp-img {
    height: 100%;
    width: 100%;
}
.company-desc {
    font-size: 18px;
    border: 0.5px solid #C4C4C4;
    textarea{
        resize: none;
    }
    .card-header{
        text-align: left !important;
    }
    .card-body {
        border-top: 0.5px solid #C4C4C4;
    }
}
