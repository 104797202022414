@import '../config/variables';
@import '../pages/userLivestatus';
@import '../pages/screenShots';
.page-content {
	@import '../pages/dashboardPage';
	@import '../pages/settingPage';
	@import '../pages/activityPage';
	@import '../pages/reportPage';
}
.company-profile {
	@import '../pages/companyProfile';
}
.view-user {
	@import '../pages/viewUser';
}
.user-dashboard {
	@import '../pages/userDashboard';
}
.view-client {
	@import '../pages/viewClient';
}
.badge-warning {
	background-color: $project-status-running;
}
.badge-success {
	background-color: $project-status-delivered !important;
}
.badge-danger {
	background-color: $project-status-hold !important;
}
.badge-secondary {
	background-color: $project-status-secondary;
}
.txt-success {
	color: $konark-success-color !important;
}
.txt-danger {
	color: $konark-danger-color !important;
}
.txt-warning {
	color: #D9AA05 !important;
}
.text-primary {
	color: $konark-primary-color;
}
.txt-secondary {
	color: #4a4646 !important;
}

.reset-btn, .reset-btn:hover {
	background-color: $konark-secondary-color;
	border-color: $konark-secondary-color;
	color: #fff;
}

.btn-primary{
	background-color: $konark-primary-color !important;
	border-color: $konark-primary-color !important;
	&:hover{
		background-color: $konark-hover-primary-color !important;
		border-color: $konark-hover-boarder-primary-color !important;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 0.2rem $konark-box-shadow-primary-color !important;
	}
}
.btn-secondary{
	background-color: $konark-secondary-color !important;
	border-color: $konark-secondary-color !important;
	&:hover{
		background-color: $konark-hover-secondary-color !important;
		border-color: $konark-hover-boarder-secondary-color !important;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 0.2rem $konark-box-shadow-secondary-color !important;
	}
}
.btn-success{
	background-color: $konark-success-color !important;
	border-color: $konark-success-color !important;
	&:hover{
		background-color: $konark-hover-success-color !important;
		border-color: $konark-hover-boarder-success-color !important;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 0.2rem $konark-box-shadow-success-color !important;
	}
}
.btn-danger{
	background-color: $konark-danger-color !important;
	border-color: $konark-danger-color !important;
	&:hover{
		background-color: $konark-hover-danger-color !important;
		border-color: $konark-hover-boarder-danger-color !important;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 0.2rem $konark-box-shadow-danger-color !important;
	}
}
.btn-warning{
	background-color: $konark-warning-color !important;
	border-color: $konark-warning-color !important;
	&:hover{
		background-color: $konark-hover-warning-color !important;
		border-color: $konark-hover-boarder-warning-color !important;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 0.2rem $konark-box-shadow-warning-color !important;
	}
}
.btn-info{
	background-color: $konark-info-color !important;
	border-color: $konark-info-color !important;
	&:hover{
		background-color: $konark-hover-info-color !important;
		border-color: $konark-hover-boarder-info-color !important;
	}
	&.focus,
	&:focus {
		box-shadow: 0 0 0 0.2rem $konark-box-shadow-info-color !important;
	}
}

.bg-primary {
	background-color: $konark-primary-color !important;
}
.bg-secondary {
	background-color: $konark-secondary-color !important;
}
.bg-info { 
	background-color: $konark-info-color;
}
.bg-warning {
	background-color: $konark-warning-color !important;
}
.bg-success {
	background-color: $konark-success-color !important;
}
.bg-danger {
	background-color: $konark-danger-color !important;
}

.text-primary {
	color: $konark-primary-color!important;
}
.text-secondary {
	color: $konark-secondary-color !important;
}
.text-info {
	color: $konark-info-color !important;
}
.text-success {
	color: $konark-success-color !important;
}
.text-warning {
	color: $konark-warning-color !important;
}
.text-danger {
	color: $konark-danger-color!important;
}


.btn-info, .btn-info:hover {
	background-color: $konark-info-color;
	border-color: $konark-info-color;
	color: #fff;
}
.active-window {
	color: $konark-primary-color;
	border-bottom: 1px solid $konark-primary-color;
}

.text-wrapper {
	width: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
.cursor-pointer {
	cursor: pointer !important;
}

.profile-user{
	height: 8rem;
	width: 8rem;
}

.no-data-img{
	width: 9rem;
	height: 9rem;
	margin:0 auto;
	img{
		width: 100%;
		height: 100%;
	}
}
.react-datepicker-wrapper {
	width: 100%;
}

.wrapper{
	border-bottom: 1px solid #b8b8b8;
}
.display-img {
	background-color: $alternate-background-color-green;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.dropdown-menu {
	min-width:179px;
	background-color: #fff !important;
	border: 0.5px solid $konark-secondary-color;
	.dropdown-item {
		background-color: #fff !important;
	}
}

// SKELETON STYLES
.skeleton-styles{
	span{
		padding: 0 !important;
	}
}

.active-card{
	span{
		margin-top: 2.5rem !important;
		margin-left: 0.5rem !important;
	}
}

// UPLOAD PAGE 
.upload-apps{
	.input-field{
		.version{
			display: flex;
			flex-direction: column;
			gap: 1rem;
			border: $file-field-border;
			border-radius: 4px;
			min-height: 2.2rem;
		}
		.file-error{
			color: $konark-danger-color;
		}
	}
	.multiple-file-field {
		border: $file-field-border;
		border-radius: 4px;
		padding: 0;
		position: relative;
	}
}
// UPLOAD PAGE

// SKELETON STYLES

.dashboard-select {
	border: 0.5px solid #979090 !important;
	border-radius: 0.3rem;
	select {
		border: none !important;
		border-radius: 0.3rem;
		height: 100%;
		background-color: #ffffff;
	}
	.select-option {
		color: #979090;
		&:hover,
		:focus {
			background-color: #ffffff;
		}
	}
}

.fullname-avatar {
	display: flex;
	align-items: center;
	justify-content: center;
	.logo {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 2.1rem;
		height: 2.1rem;
	}
}

// Generic Accordian
.accordion-section{
	margin-top: 1.5rem;
}

.generic-accordion {
	color: $konark-primary-color;
	border: 1px solid #c4c4c4 !important;
	border-radius: 0.8rem !important;
	margin-bottom: 1rem;
	overflow: hidden !important;
	.accordion-header {
		display: flex;
		align-items: center;
		justify-content: space-around;
		height: 3.5rem;
		background-color: white;
		.left-section {
			width: 44%;
			h6 {
				margin: 0 !important;
			}
		}
		.right-section {
			display: flex;
			align-items: center;
			width: 35%;
			justify-content: center;
			gap: 0.2rem;
			.icon {
				i {
					font-size: 1.5rem;
				}
			}
		}
		.end{
			justify-content: flex-end !important;
		}
	}
	.accordion-collapse {
		.collapse-group:nth-child(odd) {
			background-color: #f6fbff;
		}

		.collapse-group {
			display: flex;
			align-items: center;
			justify-content: space-around;
			padding: 0.3rem 0;
			border-radius: 0.6rem;
			margin: 0.5rem 0;
			.avatar-section {
				width: 35%;
				.avatar-title {
					height: 1.7rem;
					width: 1.7rem;
				}
			}
			h6 {
				width: 44%;
				text-align: left;
			}
		}

		.percentage{
			margin-left: -1rem;
		}
	}
}

// Carousel Styles

.screenshot-container {
	.screenshot-item {
		padding-bottom: 0.8rem;
		.img-section {
			position: relative;
			.overlay {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				height: 100%;
				width: 100%;
				opacity: 0;
				transition: 0.5s ease;
				background-color: #00000099;
				.text {
					color: white;
					font-size: 0.875rem;
					position: absolute;
					bottom: 0.5rem;
					left: 0.5rem;
				}
			}
			&:hover {
				.overlay {
					opacity: 1;
				}
			}
		}
	}
}

.screenshot-modal {
	min-width: 65%;
	a {
		text-decoration: none;
	}
	.screenshot-carousel {
		.screenshot-carausel-prev {
			margin-left: -1.5rem;
			max-height: 40.6rem;
		}
		.screenshot-carausel-next {
			margin-right: -1.5rem;
			max-height: 40.6rem;
		}
		.carousel-img {
			width: 100%;
			min-height: 650px;
			display: inline-block;
			pointer-events: none;
		}
		.carousel-header {
			.user {
				font-size: 1.4rem;
				font-weight: 800;
			}
			.info {
				h4 {
					font-size: 1.4rem;
					font-weight: 500;
				}
			}
			.date {
				font-size: 1rem;
			}
		}
	}
}

// Super Admin Pages Styles

// Common Styles
.input-group-prepend {
	margin-top: 0.5rem;
	position: absolute;
	right: 0;
	.input-group-text {
		margin-top: -0.55rem;
		font-size: 1rem;
	}
} 
.forms-submitted, .superadmin-dashboard{
	margin-top: 1rem;
	.actions-dropdown {
			background-color: $konark-primary-color;
			padding: 0.3rem;
			border-radius: 0.3rem;
			color: #ffffff;
			.actions-toggle {
					gap: 0.3rem;
					display: inline-flex;
					align-items: center;
				}
			
		}
	.activity-card{
		border-radius: 0.3rem;
		border: 0.5px solid #C4C4C4 !important;
		padding: 1rem;
	}
	.active-cards{
		span,img{
			padding: 0.5rem;
		}
		&.forms-submitted{
			background-color:  rgba(151, 71, 255, 0.30);
		}
	}
}

// Dashboard Page
.superadmin-dashboard{
	.table-special{
		.table-body{
			color: $konark-primary-color !important;
		}
	}
}


// Forms-Submiited Page
.forms-submitted{
	.forms-submitted-section{}
}


// Super Admin Pages Styles

// Calendar Styles
.fc {
	.week-offs {
		background-color: $view-project-bg-color;
	}
	.fc-view-harness {
		border: 0.5px solid $konark-secondary-color;
		border-radius: 0.4rem;
	}
	.fc-toolbar-title {
		color: $konark-primary-color;
		font-weight: 900;
		font-size: 1.5rem !important;
	}
	.fc-today-button.btn {
		background-color: $konark-info-color !important;
		border-color: $konark-info-color !important;
		&:hover{
			background-color: $konark-hover-info-color !important;
			border-color: $konark-hover-boarder-info-color !important;
		}
		&.focus,
		&:focus {
			box-shadow: 0 0 0 0.2rem $konark-box-shadow-info-color !important;
		}
	}
	.fc-col-header-cell {
		border: none !important;
		.fc-scrollgrid-sync-inner {
			border-bottom: 0.3px solid $konark-primary-color;
		}
		.fc-col-header-cell-cushion {
			text-decoration: none;
			color: $konark-primary-color;
			font-size: 1.4rem;
			font-weight: 600;
			background-color: #fff !important;
		}
	}
	.fc-day {
		border: 0.3px solid $konark-primary-color;
	}
	.fc-scrollgrid-sync-table {
		.fc-daygrid-day-frame {
			.fc-daygrid-day-top {
				padding: 0.5rem 0.3rem 0 0;
				.fc-daygrid-day-number {
					color: $konark-primary-color !important;
					text-decoration: none;
					font-size: 1.3rem;
					padding: 1rem;
				}
			}
		}
	}
}

.fc-toolbar-chunk .btn-group .btn{
	color: #fff !important;
}

.upcoming-event-calendar {
	.upcomming-event.table-scroll {
		max-height: unset !important;
	}
}
// .fc .fc-view-harness{
// 	border: 0.5px solid #c5c5c5;
// }
.fc-daygrid-day-events{
	display: flex;
	justify-content: center;
	.fc-event{
		border-radius: 0.625rem;
		background-color: #9747FF;
		.fc-event-title{
			color: #fff;
		}
	}
}
.dropdown-avatar-tab {
	button[aria-expanded='true'] {
		border: 0.8px solid $konark-secondary-color;
		border-radius: 0.6rem;
		box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
		.down-arrow {
			transform: rotate(180deg);
		}
	}
	button.download-app {
		border: 0.8px solid $konark-secondary-color;
		padding: 5px;
	}
	.dropdown-avatar {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 0.4rem;
		.avatar-right {
			p {
				font-size: 1rem;
				font-weight: 500;
				color: $konark-primary-color;
				text-align: left;
				padding: 0;
				margin-bottom: -0.3rem;
			}
			.designation {
				font-size: 0.9rem;
				font-weight: 400;
				color: $konark-primary-color;
				text-align: left;
			}
		}
		.down-arrow {
			i {
				font-size: 1.7rem;
				color: $konark-primary-color;
			}
		}
		@media (max-width: 500px) {
			margin-left: -0.4rem;
		}
	}
	.dropdown-menu-end.options {
		width: 100%;
		span {
			color: $konark-primary-color;
			font-size: 1rem;
		}
		img {
			width: 1.4rem;
		}
		.windows, .macos, .linux {
			span {
				font-size: 14px;
			}
		}
		.windows {
			border-top: 0.8px solid $konark-secondary-color;
		}
	}
}
.camera-icon {
	border-radius: 11px;
    padding: 2px 4px 1px;
	cursor: pointer;
}
.dropdown-avatar-tab.download {
	.dropdown-menu-end.dropdown-menu {
		border-radius: 0 0 5px 5px !important;
		border-top: none !important;
		transform: translate(0px, 33px) !important;
	}
	button[aria-expanded='true'] {
		border: 0.8px solid $konark-secondary-color;
		border-bottom: none;
		border-radius:  4px 4px 0 0;
	}
	.dropdown-menu {
		min-width: 0;
	}
}
a{
	text-decoration: none;
}

.projects-table {
	.status {
		width: 80px;
		font-weight: 600;
		padding: 0.125em 0.313em;
	}
}

.export-tab {
	cursor: pointer;
	font-size: 1rem;
	color: $konark-primary-color;
	font-weight: 600;

	img {
		width: 1.4rem;
	}
}

.checkbox-tab {
	.checkbox-selected {
		height: 1.4rem;
		width: 1.4rem;
		&:checked {
			accent-color: $konark-primary-color;
		}
	}
}

.avatar-list-container span:nth-child(5n-1) {
	.avatar-title {
		background: $alternate-background-color-grey;
	}
}
.avatar-list-container span:nth-child(5n-2) {
	.avatar-title {
		background: $alternate-background-color-mauve;
	}
}
.avatar-list-container span:nth-child(5n-3) {
	.avatar-title {
		background: $alternate-background-color-green;
	}
}
.avatar-list-container span:nth-child(5n-4) {
	.avatar-title {
		background: $alternate-background-color-blue;
	}
}
.avatar-list-container span:nth-child(5n-5) {
	.avatar-title {
		background: #ffb8b8;
	}
}

.avatar-list-container tr {
	.avatar-title {
		border: #28397f;
	}
}

.avatar-list-container {
	display: flex;
	a {
		text-decoration: none;
	}
	.avatar-xxs {
		text-align: center;
		text-decoration: none;
		.avatar-title {
			color: $project-avatar-text-color !important;
			height: 1.8rem;
			width: 1.8rem;
			display: flex;
			align-items: center;
			justify-content: center;
			border: $project-avatar-border;
			margin-right: -0.3rem;
			font-size: 0.85rem;
			font-weight: 400;
		}
	}
}


.export {
	.export-dropdown-menu {
		background-color: #fff;
		border: 0.5px solid $konark-secondary-color;
		padding: 0;
	}
	.export-dropdown-item {
		color: $project-avatar-text-color;
	}
}

.card-main {
	.progress-bar {
		background-color: $konark-primary-color;
	}
	.project-card-icon {
		filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(146deg) brightness(115%) contrast(101%);
	}
	box-sizing: border-box;
	padding: 2.5rem 0;
	.project-card-container {
		border: 1px solid $konark-table-header;
		box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
		border-radius: 1rem;
		.project-card-body {
			padding: 0.5rem;
			border-radius: 1rem;
			.name {
				font-weight: 700;
				font-size: 1.2rem;
				color: $project-card-heading;
				width: 100%;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}
			.project-card-details {
				padding: 1.4rem 2rem;
				.team-section,
				.manager-section {
					display: flex;
					gap: 3%;
				}
				.team-section,
				.manager-section,
				.productivity-section {
					span {
						font-size: 1rem;
						font-weight: 600;
						color:#615d5d;
					}
				}
			}
		}
	}
}
// Pop Over Styles
.morePopOver {
	color:#0B83ED;
	float:right;
	margin-left: auto;
	background: #0B83ED30;
	border-radius: 10px;
	font-family: "Open Sans";
	font-size: 11px;
	padding: 4px;
	order: 99;
	position: absolute;
	top: 3px;
	right: 0; 
}
.popOverbody{
	font-size:14px;
	font-family:'Open Sans';
	color:#615d5d;
	line-height:20.62px;
	padding: 1rem 1rem 0 0;
}
.popOverbody ul{
	max-height: 200px;
	overflow: auto;
}
.popover{
	background:#fff;
	border:#BDBDBD solid 0.5px;
	border-radius:5px
}

// Modal Styles
.archive-modal {
	.modal-body {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	min-height: 25rem !important;
	min-width: 30rem !important;
}
.report-filter-section {
	.react-modal-select__multi-value{
		background-color: #0FBEC915 ; 
		color: #0FBEC9;
		border-radius: 25px;
	}
	.css-wsp0cs-MultiValueGeneric{
		color: inherit;
	}
	.react-modal-select__placeholder {
	  font-size: 1rem;
	}
	.react-datepicker-wrapper {
	  width: 100%;
	}
	.react-modal-select__indicators{
	  svg{
		  color: #28397f;
	  }
	}
	.react-modal-select__menu {
	  background-color: #ffffff !important;
	  .react-modal-select__menu-list {
		border-bottom: 0.2px solid $konark-secondary-color;
		.react-modal-select__option {
		  &:hover {
			background-color: $select-option-bg-color;
		  }
		}
	  }
	}
}
.react-modal-select__menu-list::-webkit-scrollbar {
	width: 8px;
}
.react-modal-select__menu-list::-webkit-scrollbar-thumb {
	background-color: #263780;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 3px;
}
.popOverbody ul::-webkit-scrollbar{
	width: 5px;
}
.popOverbody ul::-webkit-scrollbar-thumb{
	background-color: #263780 !important;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
	border-radius: 3px !important;
}
.popOverbody li::marker{
	color: #263780;
	font-size: 14px;
}
.add-new-modal {
	.sample-file-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 0.4rem;
		border: none;
		background-color: #ffffff;
		color: $konark-primary-color;
		font-weight: 600;
		font-size: 1rem;
		.button-container {
			width: 0.7rem;
		}
		img {
			width: 100%;
		}
	}
	.datepicker-icon {
		width: 1.3rem;
		height: 1rem;
		img {
			width: 100%;
		}
	}
	.termsconditions-col{
		label{
			color:$konark-primary-color;
			font-weight: 700;
		}
	}
	min-width: 55%;
	.modal-header {
		border-bottom: 1px solid $konark-secondary-color;
		padding-bottom: 0;
		background-color: #ffffff;
		.active-window {
			color: $konark-primary-color;
			border-bottom: 1px solid $konark-primary-color;
		}
	}
	.time-log-header {
		border-bottom: 1px solid $konark-primary-color;
		color: $konark-primary-color;
	}
	.modal-body {
		.morePopOver {
			color:#0B83ED;
      		float:right;
      		margin-left: auto;
      		background: #0B83ED30;
      		border-radius: 10px;
      		font-family: "Open Sans";
			font-size: 11px;
      		padding: 4px;
			order: 99;
			position: absolute;
			top: 3px;
			right: 0; 
		}
		.weekoff-options {
			display: flex;
			flex-wrap: wrap;
			gap: 0.8rem;
			justify-content: center;
			input[type='checkbox'] {
				width: unset;
				height: 1.4rem;
				width: 1.4rem;
				&:checked {
					accent-color: $konark-primary-color;
				}
			}
			.entry {
				display: flex;
				align-items: center;
				gap: 0.5rem;
				padding: 0.3rem;
				span {
					font-size: 0.9rem;
					color: $konark-primary-color;
					text-transform: capitalize;
				}
			}
		}
		min-height: 65% !important;
		.react-modal-select__control {
			position: relative;
			overflow: auto;
			max-height: 4rem;
		}
		.react-modal-select__placeholder {
			font-size: 1rem;
		}
		.react-datepicker-wrapper {
			width: 100%;
		}
		.react-modal-select__multi-value{
			background-color: #0FBEC915 ; 
			color: #0FBEC9;
			border-radius: 25px;
		}
		.css-wsp0cs-MultiValueGeneric{
			color: inherit;
		}
		.react-modal-select__menu {
			background-color: #ffffff !important;
			.react-modal-select__menu-list {
				border-bottom: 0.2px solid $konark-secondary-color;
				.react-modal-select__option {
					&:hover {
						background-color: $select-option-bg-color;
					}
				}
			}
		}
				form {
			padding: 0 1rem;
			.termsconditions-col {
				textarea {
					border: 0.5px solid $konark-secondary-color;
				}
			}
		}
		background-color: #ffffff;

		.reset-btn,
		.submit-btn {
			padding: 8px 19px !important;
			font-weight: 500 !important;
			font-size: 1rem !important;
		}

		.multiple-field-form {
			padding: 0 6rem;
			.multiple-file-field {
				border: $file-field-border;
				border-radius: 4px;
				padding: 0;
				position: relative;
				.multiple-file-field-label {
					background-color: $auth-page-input-color;
					font-size: 1rem;
					font-weight: 500;
					width: 30%;
					padding-bottom: 0;
					display: flex;
					justify-content: center;
					align-items: center;
				}
				.multiple-file-field-input {
					border-bottom: none;
					&::-webkit-file-upload-button {
						visibility: hidden;
						color: transparent;
					}
				}
			}
			label {
				color: $konark-primary-color;
				font-weight: 600;
				font-size: 1.2rem;
				padding-bottom: 1rem;
			}
			.button-container {
				display: flex;
				width: 100%;
				justify-content: center;
			}
		}

		input,textarea,
		.css-1s2u09g-control {
			.css-6j8wv5-Input {
				&::placeholder {
					color: $modal-inputs-border;
					font-size: 1rem;
				}
			}
			.css-1okebmr-indicatorSeparator {
				display: none;
			}
			border: none;
			width: 100%;
			border-radius: 0;
			border-bottom: 1px solid #b8b8b8;
			background: transparent;
			box-sizing: border-box;
			outline: none;
			caret-color: $auth-page-input-color;
			color: #000000;
			font-size: 1rem;
			&::placeholder {
				color: $modal-inputs-border;
				opacity: 1;
				font-size: 1rem;
			}
		}
	}
}
.modal-width {
	min-width: 400px;
	.modal-header {
		padding-bottom: 20px;
		align-items: center;
		display: flex;
		justify-content: center;
		font-size: 14px;
		font-weight: 700;
		color: '#28397F';
	}
	.modal-body {
		align-items: center;
		display: flex;
		justify-content: center;
	}
	.reset-btn,
	.submit-btn {
		margin-left: 60px !important;
		align-self: center !important;
		font-size: 12px !important;
		font-weight: 700 !important;
	}
}

.user-table {
	a {
		text-decoration: none;
	}
	.time-log-entry {
		text-align: center;
		span {
			font-size: 0.9rem;
			font-weight: 600;
		}
	}
}

.search-bar{
	.search-icon {
		padding: 0.4rem;
		.ri-search-line {
			padding: 0.4rem;
		}
	}
}

.search-box {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 0.5px solid $konark-secondary-color;
	border-radius: 0.3rem;
	.search-icon {
		padding: 0.4rem;
		.ri-search-line {
			padding: 0.4rem;
		}
	}
}
.css-1pahdxg-control{
	box-shadow: none !important;
	border: none !important;
	border-bottom: 1px solid #b8b8b8 !important;
    border-radius: 0 !important;
}
.css-1okebmr-indicatorSeparator {
	display: none !important;
}
.filter-styles, iframe, embed {
	.css-tlfecz-indicatorContainer:hover,
	.css-tlfecz-indicatorContainer{
		color: #00000000;
	}
	.react-select__multi-value{
		background-color: #0FBEC915 ; 
		color: #0FBEC9;
		border-radius: 25px;
	}
	.css-wsp0cs-MultiValueGeneric{
		color: inherit;
	}
	
	.react-select__control,
	.react-select__control--is-focused,
	.react-select__control--menu-is-open {
		border: 1px solid $konark-secondary-color !important;
		border-radius: 5px !important;
		.archive-modal .react-select__indicator-separator {
			display: none;
		}
		.react-select__placeholder {
			color: $konark-primary-color;
		}
		.react-select__value-container {
			justify-content: start;
		}
		.react-select__indicators {
			padding: 8px;
			
		}
		.react-select__indicator-separator {
			display: none;
		}
	}
	.react-select__menu{
		.react-select__menu-list {
			border-radius: 5px;
			background-color: #ffffff;
			.react-select__option {
				border-bottom: 0.2px solid $konark-secondary-color;
				&:hover {
					background-color: #E9EBEC;
					border-bottom: 0.2px solid $konark-hover-boarder-primary-color;
				}
				&.react-select__option--is-selected:hover {
					background-color: #E9EBEC; 
					color: $konark-hover-boarder-primary-color; 
				  }
			}
		}
	}
}
.profile-logo {
	box-sizing: border-box;
	height: 2rem;
	width: 2rem;
	img {
		border-radius: 50%;
		width: 100%;
	}
}

// Generic View Pages Cards Styles
.projects-clients-card {
	border: 0.5px solid $konark-secondary-color;
	.client-card-header {
		border-bottom: 0.5px solid $auth-page-input-color;
		h3 {
			margin: 0;
			font-size: 1.2rem;
		}
		.details-btn {
			border: none;
			border-radius: 0.6rem;
			background-color: $konark-primary-color;
			color: #ffffff;
			padding: 0.4rem;
			font-size: 0.625rem;
		}
	}
	.client-card-body {
		.logo,
		img {
			height: 2rem;
			width: 2rem;
			text-align: center;
			line-height: 2rem;
		}
		h6 {
			font-size: 1.1rem;
		}
		p {
			color: $konark-primary-color;
			font-weight: 600;
			font-size: 1.1rem;
		}
		.name {
			color: $project-card-heading;
			font-size: 1rem;
			margin: 0;
		}
		.designation {
			font-size: 0.9rem;
			margin: 0;
		}
		.user-logo:nth-child(5n-1) {
			.avatar-title {
				background: #ffb8b8;
			}
		}
		.user-logo:nth-child(5n-2) {
			.avatar-title {
				background: #ada8fa;
			}
		}
		.user-logo:nth-child(5n-4) {
			.avatar-title {
				background: #a2dfce;
			}
		}
		.user-logo:nth-child(5n-3) {
			.avatar-title {
				background: #ecb907;
			}
		}
		.user-logo:nth-child(5n-5) {
			.avatar-title {
				background: #b6d1e8;
			}
		}
	}
}

.view-user {
	.filter-button{
		visibility: visible !important;
		width:7rem;
		margin-left: 0.5rem;
	}
}

// View Projects Section
.view-projects{
	h6,
	span {
		font-weight: 600;
	}
	h6 {
		color: $project-avatar-text-color;
	}
	span {
		color: $konark-primary-color;
	}
	.project-profile-card {
		.profile-card-body {
			background-color: $image-card-bg-color !important;
		}
		h5,
		p {
			color: $konark-primary-color;
		}
		h5 {
			font-size: 1.3rem;
		}

		h1 {
			font-size: 5rem;
		}
	}

	.about-project {
		border: 0.5px solid $konark-secondary-color;
		h6,
		span {
			font-size: 1rem;
		}
		.type-project {
			background-color: $view-project-bg-color;
			padding: 0.2rem 0.3rem;
			border-radius: 0.3rem;
			text-transform: capitalize;
		}
		.project-status {
			padding: 0.2rem 0.3rem;
			border-radius: 0.3rem;
		}
		@media (max-width: 992px) {
			margin-top: 2rem;
		}
	}
	.table-scroll {
		max-height: 245px !important;
		overflow: auto;
	}
	.table-scroll::-webkit-scrollbar {
		width: 6px;
	}
	.table-scroll::-webkit-scrollbar-thumb {
		background-color: #263780;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 3px;
	}
	.table-scroll::-webkit-scrollbar-track {
		background-color: #f2f2f2;
	}
	.table-scroll::-webkit-scrollbar {
		width: 8px;
	}
	.filter-button{
		visibility: visible !important;
	}
}

//Pagination Styles
.pagination-styles {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 0.3rem;
	padding: 0.5rem 0;

	.pagination-btn {
		border: 0.5px solid $konark-secondary-color;
		padding: 0.5rem 0.3rem;
		border-radius: 0.5rem;
		background-color: #ffffff;
		color: $konark-primary-color;
		font-weight: 600;
		font-size: 0.9rem;
		&:hover{
			background-color: $konark-primary-color;
			color: #ffffff;
		}
		&:active{
			background-color: $konark-primary-color;
			color: #ffffff;
			border: 0.5px solid $konark-primary-color;
		}
	}

	.page-entries {
		color: #000000;
		span {
			font-weight: 700;
		}
	}
}
.image-loader {
	max-width: 10%;
	height: auto;
	opacity: 0.5;
}
.fc-day.weekoff-event {
	background-color: #ffcccc;
	color: #ff0000;
	border-color: #ff0000;
}
.fc-day.normal-event {
	color: black;
}
.custom-file-label {
	position: absolute;
	bottom: 0;
	z-index: 1;
	height: calc(2.0625rem + 2px);
	padding: 0.375rem 0.75rem;
	line-height: 1.5;
	border-radius: 2px;
	font-size: 12px !important;
	font-weight: 400 !important;
	margin-top: 144px;
}
.custom-file-label:after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 3;
	display: block;
	height: calc(calc(2.0625rem + 2px) - 1px * 2);
	padding: 0.375rem 0.75rem;
	line-height: 1.5;
	color: #4f5467;
	background-color: #f8f9fa;
	border-left: 1px solid #e9ecef;
	border-radius: 0 2px 2px 0;
}
.switchToggle input[type=checkbox]{height: 0; width: 0; visibility: hidden; position: absolute; }
.switchToggle label {cursor: pointer; text-indent: -9999px; width: 52px; max-width: 52px; height: 22px; background: #D71509; display: block; border-radius: 100px; position: relative; margin-bottom: 0rem;}
.switchToggle label:after {content: ''; position: absolute; top: 2px; left: 2px; width: 18px; height: 18px; background: #fff; border-radius: 90px; transition: 0.3s; }
.switchToggle input:checked + label, .switchToggle input:checked + input + label  {background: #28a745; }
.switchToggle input + label:before, .switchToggle input + input + label:before {content: 'No'; position: absolute; top: 2px; left: 28px; width: 26px; height: 26px; border-radius: 90px; transition: 0.3s; text-indent: 0; font-size:.75rem; color: #fff; }
.switchToggle input:checked + label:before, .switchToggle input:checked + input + label:before {content: 'Yes'; position: absolute; top: 2px; left: 9px; width: 26px; height: 26px; border-radius: 90px; transition: 0.3s; text-indent: 0; font-size:.75rem; color: #fff; }
.switchToggle input:checked + label:after, .switchToggle input:checked + input + label:after {left: calc(100% - 2px); transform: translateX(-100%); }
.switchToggle label:active:after {width: 60px; } 
.toggle-switchArea { margin: 10px 0 10px 0; }
.carousel-control-prev,
.carousel-control-next{
	cursor: pointer;
	background: black;
	width: 1.5rem;
}
.apex-charts{
	.apexcharts-tooltip{	
	background-color:white !important;
		color: #000000 !important;
	}
}
.bar-chart{
	.apexcharts-tooltip{
		background-color:white !important;

		color: #000000 !important;
	}
}
.react-datepicker__month-container{
	.react-datepicker__header{
		background-color: #fff !important;
		border-bottom:0 !important;
		select{
			border: none !important;
			background: #fff !important;
		}
		.react-datepicker__day-names{
			opacity: 0.5;
		}
	}
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after{
	border-bottom: none !important;
    border-top-color: #fff !important;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
	border-top: none !important;
    border-bottom-color: #fff !important;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before{
    border-top-color: #aeaeae !important;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before{
    border-bottom-color: #aeaeae !important;
}
.react-datepicker__triangle{
	left: 51% !important;
    transform: translate(50%, 0px) !important;
}
.react-datepicker-popper[data-placement="top-start"]{
	inset: auto auto 0px 50% !important;
    transform: translate(-50%, -41px) !important;
}
.react-datepicker-popper[data-placement="bottom-start"]{
	inset: 0px auto auto 50% !important;
    transform: translate(-50%, 41px) !important;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
	font-size: 0.875rem !important;
    font-weight: 700 !important;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
	font-weight: 400 !important;
	font-size: 15px !important;
}
.btn:disabled{
	cursor: not-allowed !important;
}