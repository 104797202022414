@import '../config/variables';

.dashboard-card {
	height: 100%;
	width: 100%;
	.activity-card {
		.arrow-up {
			background-color: #a2dfce;
			color: #3f8842;
			font-weight: 500;
		}
		.arrow-down {
			background: rgba(245, 151, 148, 0.4);
			color: #d71509;
			font-weight: 500;
		}
		background: #ffffff;
		border: 0.5px solid #f5f5f5;
		border-radius: 5px;
		display: flex;
		align-items: center;
        i{
            font-size: 1.1rem;
        }
	}
	.activity-card.not-loading {
		&:hover {
			background-color: $user-dashboard-widgets-bg !important;
		}
	}
	.view-all {
		font-size: 10px;
		bottom: 0;
		padding-bottom: 10px;
		top: unset;
	}
}
.table-card {
	.table {
		table-layout: fixed;
	}
}

.active-cards {
	border-radius: 5px;
	padding-bottom: 9px;
	padding-left: 6px;
	padding-right: 6px;
	padding-top: 7px;
	&.active-projects {
		background: rgba(11, 131, 237, 0.5);
	}
	&.active-users {
		background: rgba(236, 185, 7, 0.24);
	}
	&.inactive-users {
		background: rgba(71, 183, 18, 0.33);
	}
}

.active-projects i.ri-stack-line {
	padding: 6px;
	padding-top: 8px;
	font-size: 24px;
	color: #0b83ed;
}
.active-users i.ri-account-circle-line {
	padding: 6px;
	font-size: 24px;
	color: #ecb907;
}
.inactive-users i.ri-account-circle-line {
	padding: 6px;
	font-size: 24px;
	color: #47b712;
}
.percentage-block {
	margin-left: 10px;
    font-weight: 900;
    .badge{
        padding: 0;
    }
}
.view-all {
	position: absolute;
	right: 0;
	margin-right: 10px;
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 14px;
	display: flex;
	align-items: flex-end;
	color: #0b83ed;
	top: 1.5rem;
	text-decoration: none;
}
.event-card {
	border: 0.5px solid #f5f5f5;
	height: 100%;
	width: 100%;
}
.client-card {
	border: 0.5px solid #f5f5f5;
	margin-bottom: 6px;
	height: 100%;
	width: 100%;
	.table {
		table-layout: fixed;
	}
}
.card-project-user {
	border: none;
	margin-top: 20px;
	.text-shade-green {
		color: $konark-success-color;
	}
}
.card-project-user-least {
	border-left: 0.5px solid #f5f5f5;
	margin-top: 20px;
	.text-shade-red {
		color: $konark-danger-color;
	}
}
.card-header {
	text-align: center;
	color: $konark-primary-color;
}
.header {
	font-size: 12px;
	font-weight: 600;
	margin-right: 163px;
}


.i-logo {
	position: absolute;
	right: 0;
	margin-right: 7px;
	font-size: 1rem;
	color: #615d5d;
	top: 0;
}

.number {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 33px;
	color: $konark-primary-color;
}
.heading {
	position: absolute;
	left: 20.33%;
	right: 46.98%;
	top: 53.33%;
	bottom: 28.89%;
	margin-top: 2px;
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	color: #6b6666;
}

.inside-logo {
	position: absolute;
	left: 0.19%;
	right: -0.19%;
	top: 0%;
	bottom: 0%;
	background: #0b83ed;
}
.table-allteam-members {
	box-sizing: border-box;
	left: 0px;
	top: 0px;
	background: #ffffff;
	border-radius: 5px;
	border: 0.5px solid #f5f5f5;
	margin-bottom: 6px;
	height: 100%;
	width: 100%;
	.table {
		table-layout: fixed;
	}
}
.table-scroll {
	max-height: 285px;
	overflow: auto;
}
.table-scroll::-webkit-scrollbar {
	width: 6px;
}
.table-scroll::-webkit-scrollbar-thumb {
	background-color: #263780;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 3px;
}
.table-scroll::-webkit-scrollbar-track {
	background-color: #f2f2f2;
}
.table-scroll::-webkit-scrollbar {
	width: 8px;
}

.table-heading {
	color: #615d5d;
	border-block-color: #e5e7eb;
	position: sticky;
	top: 0px;
	background-color: #fff;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
.table-data {
	color: #615d5d;
	border: none;
}

.active-clients {
	box-sizing: border-box;
	left: 51.03%;
	right: 25.43%;
	top: 0%;
	bottom: 3.85%;
	background: #ffffff;
	border: 0.5px solid #f5f5f5;
	border-radius: 5px;
}
.screenshot-item {
	img {
		width: 100%;
		height: 100%;
	}
}
.screenshot-card {
	border: 0.5px solid #f5f5f5;
}
.select-list {
	box-sizing: border-box;
	position: absolute;
	left: 0%;
	right: 0%;
	top: 5.56%;
	bottom: 5.56%;
	background: #ffffff;
	border: 0.5px solid #979090;
	border-radius: 5px;
}
.refresh-logo {
	width: 32px;
	height: 32px;
	left: 1329.34px;
	top: 69px;
	background: $konark-primary-color;
	border-radius: 5px;
	color: white;
}
.upcomming-event {
    .day-badge{
        padding: 0.9rem 0.5rem;
        font-weight: 600;
    }
    .bg-success{
        background-color:#2A7209 !important;
    }
    .bg-warning{
       background-color: #ECB907 !important;
    }
    .date-badge{
        font-size: 0.9rem;
        font-weight: 400;
        color:$project-avatar-text-color;
    }
	.card-body {
		border: 0.5px solid #f5f5f5;
		border-radius: 5px;
	}
	.card:hover {
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
	}
}
.most-used-app {
	background: rgba(162, 223, 206, 0.53);
	border-radius: 5px;
	padding-bottom: 9px;
	padding-left: 13px;
	padding-right: 13px;
	padding-top: 7px;
}
.event-block {
	border-radius: 5px;
	padding: 8px 8px 8px 8px;
	color: white;
}
.apps-block {
	margin: 10px 5px 0px 0px;
	font-size: 10px;
	background-color: #0fbec9;
	color: white;
	border-radius: 5px;
	padding: 2px 10px;
	white-space: nowrap;
}
.most-used-apps-container {
	display: flex;
  	flex-wrap: wrap; /* Allow the main div to wrap its children */
  	overflow: hidden; 
	width:100%
}
a {
	text-decoration: none;
}