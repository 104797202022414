@import '../config/variables';

.skeleton-box {
	display: inline-block;
	height: 1em;
	position: relative;
	overflow: hidden;
	background-color: #dddbdd;  
	animation: skeleton-loading 2s linear infinite alternate;
  } 
  @keyframes skeleton-loading {
	  0% {
		background-color: rgb(218, 226, 231);
	  }
	  100% {
		background-color: rgb(240, 243, 245);
	  }
  }
