@import '../config/variables';

.profile-card {
    background-color: #E3F5FE !important;
}
.card-border {
    border-radius: 4px;
    border: 0.5px solid #C5C5C5;
    color: #615D5D;
    .card-header{
        text-align: right !important;
        font-size: small;
    }
}
.card-body {
    h6 {
        color: $blue
    }
}
.card-header.card-header-border{
    border-bottom: 0.5px solid #C5C5C5;
}
.card-body.card-body-border {
    border-top: 0.5px solid #C5C5C5;
}

.team-card {
    img {
        height: 2rem;
        width: 2rem;
    }
    .card-header{
        text-align: left !important;
        font-size: small;
    }
    .sub-text {
        font-size: small;
    }
    .badge-info {
        background-color: #E3F5FE;
        font-size: 12px;
        color: white;
        border-radius: 50px;
        display: inline-block;
        font-weight:600;
        color: $blue;
        text-decoration: none;
    }
    .badge-info:hover{
        border: 0.5px solid #D71509;
        span {
            display: inline-block;
        }
    }
    span i {
        color: #D71509;
        font-size: 12px;
    }
}
.react-modal-select__control {
    overflow: auto;
    max-height: 4rem;
    width: 14.5rem;
}
.react-modal-select__placeholder {
    font-size: 1rem;
}
.react-datepicker-wrapper {
    width: 100%;
}
.react-modal-select__menu {
    background-color: #ffffff !important;
    .react-modal-select__menu-list {
        border-bottom: 0.2px solid $konark-secondary-color;
        .react-modal-select__option {
            &:hover {
                background-color: $select-option-bg-color;
            }
        }
    }
}
