@import '../config/variables';

@mixin auth-background-layers {
	background-image: url('../../images/Auth-BG-IMG.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	font-family: 'Open Sans';
	height: 100%;
	min-height: 100vh;

	// width: 100vw; need to be reviewed
	.logo {
		border-radius: 0px;
		width: 150px;
		height: 75px;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.logo-mobile {
		position: absolute;
		left: 50%;
		transform: translate(-50%, 0%);
		display: none;
		top: 0;
		margin-top: 3.5rem;
		height: 75px;
		width: 150px;

		img {
			width: 100%;
			height: 100%;
		}
	}

	@media (max-width: 769px) {
		.logo {
			display: none;
		}

		.logo-mobile {
			display: inline-block;
		}
	}
}

@mixin auth-button-styles {
	padding: 0.8rem 1.8rem;
	border-radius: 10px;
	border: none;
	font-style: normal;
	font-weight: 600;
	font-size: 1.2rem;

	@media (min-width: 770px) and (max-width: 992px) {
		padding: 7px 20px !important;
		font-size: 1.2rem !important;
	}

	@media (max-width: 769px) {
		padding: 0.5rem 1.1rem !important;
		font-size: 1rem !important;
	}
}

.auth-page-wrapper {
	@include auth-background-layers;
	color: $auth-page-text-color;
	font-weight: normal;
	font-style: normal;
	position: relative;

	.auth-logo-and-text {
		display: flex;
		flex-direction: column;

		.auth-redirect-links {
			margin-top: auto;
			padding-left: 2.0625rem;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding-bottom: 1.5rem;

			h4 {
				font-weight: 300;
				font-size: 3rem;
				line-height: 65px;
				margin-bottom: 1rem;
			}

			h5 {
				font-weight: 300;
				font-size: 2.5rem;
				line-height: 65px;
				margin-bottom: 1rem;
			}

			span {
				font-size: 1.6rem;
				line-height: 33px;
				font-weight: 400;
				margin-bottom: 1rem;
			}

			button {
				padding: 0.625rem 1.25rem;
				font-size: 1.4rem;
			}

			i {
				font-size: 1.9rem;
				padding-right: 0.3rem;
			}

			@media (min-width: 770px) and (max-width: 992px) {

				h5,
				h4 {
					font-size: 2rem;
				}

				span {
					font-size: 1.3rem;
				}

				i {
					font-size: 1.6rem;
					margin-bottom: .25rem;
				}
			}

			@media (max-width: 769px) {
				padding-left: 0;
				margin-left: 0 !important;
				margin-top: 2.5rem !important;
				position: unset;
				width: 100%;
				justify-content: center;
				align-items: center;
				text-align: center;

				h5,
				h4 {
					font-size: 2rem;
					padding: 0;
				}
			}
		}
	}

	.logo {
		margin-bottom: auto;
		margin-left: 20px;
		margin-top: 15px;
	}

	.auth-container {
		display: flex;
		justify-content: space-between;
		flex-direction: row;

		@media (max-width: 769px) {
			flex-direction: column-reverse;
		}
	}

	.auth-2fa-code {
		input {
			background-origin: 'content-box';
			padding-right: 2.5rem;
		}

		p {
			color: black;
			padding: 0.5rem;
			margin: 0;
			cursor: pointer;
		}
	}

	.input-group {
		.input-group-prepend {
			margin-top: 0.5rem;
			position: absolute;
			right: 0;

			.input-group-text {
				margin-top: -0.55rem;
				color: $auth-page-text-color !important;
				font-size: 1.2rem;
			}
		}
	}

	.password-addon {
		color: #f6fbff !important;
	}

	button.btn {
		@include auth-button-styles;
	}
	/* To be reduced only done because of autofill issue*/
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active,
	input:focus-visible,
	input:focus-within,
	input:focus,
	input {
		border: none;
		width: 100%;
		border-radius: 0;
		border-bottom: 1px solid #b8b8b8;
		height: 2.8rem;
		background-color: transparent !important;
		box-sizing: border-box;
		outline: none;
		caret-color: $auth-page-input-color;
		-webkit-text-fill-color: $auth-page-input-color !important;
		color: $auth-page-input-color !important;

		&::placeholder {
			color: $auth-page-input-color;
			opacity: 1;
			font-size: 1.2rem;
		}

		&:focus {
			color: $auth-page-input-color;
		}
	}

	/* Autofill styles for Chrome, Safari, Edge */
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		border-bottom: 1px solid #b8b8b8 !important;
		-webkit-box-shadow: 0 0 0 1000px transparent inset !important;
		/* Trick to force transparent background */
		-webkit-text-fill-color: $auth-page-input-color !important;
		/* Set text color */
		caret-color: $auth-page-input-color !important;
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		-webkit-background-clip: text;
		-webkit-text-fill-color: #ffffff;
		transition: background-color 5000s ease-in-out 0s;
		box-shadow: inset 0 0 20px 20px #23232329;
	}

	input {
		border: none;
		width: 100%;
		border-radius: 0;
		border-bottom: 1px solid #b8b8b8;
		height: 2.8rem;
		background-color: transparent !important;
		box-sizing: border-box;
		outline: none;
		caret-color: $auth-page-input-color;
		-webkit-text-fill-color: $auth-page-input-color !important;
		color: $auth-page-input-color !important;

		&::placeholder {
			color: $auth-page-input-color;
			opacity: 1;
			font-size: 1.2rem;
		}

		&:focus {
			color: $auth-page-input-color;
		}
	}

	/* To be reduced only done because of autofill issue*/

	.remember-me {
		display: flex;
		flex-direction: row;
		align-items: center;

		input[type='checkbox'] {
			margin-top: 0.02rem;
			height: 0.8rem;
			margin-right: 0.5rem;
			width: auto;
		}

		span {
			white-space: nowrap;
		}
	}

	a {
		text-decoration: none;
	}

	@media (max-width: 769px) {
		input {
			height: 2.2rem;

			&::placeholder {
				font-size: 1rem;
			}
		}

		.input-group {
			.input-group-prepend {
				position: absolute;
				right: 0;
				margin-top: 0rem;

				.input-group-text {
					color: $auth-page-text-color !important;
					font-size: 1rem;
					margin-top: 0;
				}

				.err {
					margin-bottom: 1rem;
				}
			}
		}

		.auth-2fa-code {
			p {
				padding: 0.25rem 0.5rem;
			}
		}
	}
}

.auth-generic-form {
	display: flex;
	justify-content: center;
	flex-direction: column;
	width: 45vw;
	min-height: 100vh;
	height: 100%;
	right: 0px;
	top: 0px;
	background: rgba(255, 255, 255, 0.15);
	box-shadow: 0px 2px 24px 1px rgba(0, 0, 0, 0.25);

	.signup-header {
		margin: 5rem 0 2rem 2rem;
	}

	.signup-form-header {
		padding-left: 0.7rem;
	}

	.form-header {
		margin: 3.5rem 0;
		text-align: center;

		h3 {
			font-size: 1.8rem;
			font-weight: 600;
			padding-bottom: 1rem;
			text-align: center;
		}

		h4 {
			font-size: 1.7rem;
			font-style: normal;
			font-weight: 600;
		}

		h5 {
			font-size: 1.5rem;
			font-style: normal;
			font-weight: 600;
		}

		h6 {
			font-size: 1.6rem;
			font-style: normal;
			font-weight: 400;
		}

		.guidance-text {
			font-size: 1.2rem;
			font-weight: 400;
			margin: 1.5rem 0;
			padding: 0 5rem;
			text-align: left;
		}
	}

	form {
		display: flex;
		justify-content: center;
		flex-direction: column;
		width: 100%;
		padding: 0 2.5rem;
		margin-bottom: 3.2rem;
	}

	&.width-65 {
		width: 65vw;

		form {
			padding: 0 1rem;
			margin-bottom: 2.2rem;
		}
	}

	&.width-42 {
		width: 42vw;
		padding: 3rem 0rem;

		form {
			padding: 0 3.5rem;
			margin-bottom: 0rem;
		}
	}

	&.width-40 {
		width: 40vw;

		form {
			padding: 0 4.5rem;
		}
	}

}

.auth-generic-text {
	width: 40vw;
	margin: 0 auto;
	min-height: 100vh !important;
	height: 100%;
	background: rgba(255, 255, 255, 0.15);
	box-shadow: 0px 2px 24px 1px rgba(0, 0, 0, 0.25);
	text-align: center;
	padding: 5rem 2rem 0 2rem;

	h4 {
		font-size: 2rem;
	}

	p {
		padding: 0;
		font-size: 1.5rem;
		margin: 2rem 0 0;
	}

	span {
		color: $auth-page-btn-background;
	}

	.logo-mobile {
		margin-top: 1rem;
	}

	&.container-404 {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		p {
			font-weight: 400;
			font-size: 1.875rem;
			margin-bottom: 1.125rem;
		}
	}

	.table-404 {
		// margin: 5px auto;
		width: 21.875rem;
		height: 18.75rem;
		margin-bottom: 7.5rem;
	}

	td {
		font-size: 600;
		font-size: 3.75rem;
	}

	.vertical-boarder {
		border-left: 3px solid #fff !important;
		border-right: 3px solid #fff !important;
	}

	.horizontal-boarder {
		border-top: 3px solid #fff !important;
		border-bottom: 3px solid #fff !important;
	}
}

// sign-up page styles
.auth-two-factor-page {
	padding: 5rem 3rem 1rem 3rem;

	.btn-light {
		color: #fff;
		border: 0.5px solid #fff;
		padding: 0.2rem 0.5rem !important;
		background-color: unset;
		font-weight: 300 !important;
		font-size: 1rem !important;
		border-radius: 2px !important;

		i {
			font-weight: 300 !important;
			font-size: 1rem !important;
		}
	}

	button {
		padding: 0.3rem 1rem;
	}
}

.two-step-enable {
	justify-content: start;
	padding: 1.5rem 3rem 1rem 3rem;

	h2 {
		font-weight: 400;
	}

	button.btn {
		padding: 0.3rem 1rem;
	}

	.form-header {
		text-align: left;
	}

	p {
		font-size: 1.125rem;
	}

	.qr-code {
		height: 9.375rem;
		width: 9.375rem;
	}

	.auth-2fa-code {
		position: relative;

		input.text-code:focus,
		input.text-code {
			padding-right: 2.5rem;
			background-color: #fff;
			color: #000 !important;
			caret-color: #000000;
			border-radius: 5px;

			::placeholder {
				color: #000 !important;
			}
		}

		span {
			position: absolute;
			top: 0;
			right: 0;
			padding: 0.5rem;
			margin: 0;
			cursor: pointer;
			font-size: 1.125rem;
		}
	}

	.auth-2fa-steps {
		text-decoration: underline;
		text-decoration-color: #ffffff4f;
	}

	.otp-inputs {
		justify-content: center;
		display: flex;

		input {
			width: 2.5rem;
			height: 2.5rem;
			background-color: #fff;
			margin-right: 1.25rem;
			justify-content: center;
			color: #000 !important;
			caret-color: #000000;
			border-radius: 0.313rem;

			::placeholder {
				color: #000 !important;
			}
		}
	}

	.backup-codes {
		background-color: white;
		color: black;
		border-radius: 5px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		span {
			text-align: center;
			padding: 1rem;
			flex: 20%;
		}

		@media (max-width: 992px) {
			span {
				flex: 33.33%;
			}
		}

		@media (max-width: 576px) {
			span {
				flex: 33.33%;
			}
		}
	}

	.action-buttons-container {
		display: flex;
		justify-content: center;
		margin: 2rem 0;

		button {
			background-color: initial;
			border: 0.5px solid #fff;
			border-radius: 2px;
			color: #fff;
			font-size: 1rem;
			font-weight: 300;
			padding: 0.2rem 0.5rem;
			margin: 0 1.5rem;

			&:hover {
				background-color: #fff;
				color: #28397f;
			}
		}
	}
}

.two-step-disable {
	align-items: center;

	h2 {
		font-weight: 400;
	}

	button.btn {
		padding: 0.3rem 1rem;
	}

	.disable-2fa-icon {
		width: 5rem;
		height: 5rem;
		margin-bottom: 1.25rem;

		@media (max-width: 769px) {
			margin-top: 5rem;
		}
	}
}


@media (min-width: 770px) and (max-width: 992px) {
	.auth-generic-form {
		width: 50vw;

		&.width-65,
		&.width-42,
		&.width-40 {
			width: 50vw;

			form {
				padding: 0 2rem;
			}
		}

		.form-header {
			.guidance-text {
				padding: 0 2.5rem;
			}
		}
	}

	.auth-generic-text {
		width: 60vw;
	}
}

@media (max-width: 769px) {
	.auth-generic-form {
		padding-top: 6rem;
		width: 100%;

		form {
			padding: 0 2rem;
		}

		&.width-65,
		&.width-42,
		&.width-40 {
			padding-top: 6rem;
			width: 100%;

			form {
				padding: 0 2rem;
			}
		}

		&.width-65 {
			form {
				padding: 0 1.5rem;
			}
		}

		min-height: unset !important;

		.welcome-text {
			display: none;
		}

		.signup-header {
			text-align: center;
			margin: 5rem 0 2rem 0;
		}

		.form-header {
			margin: 4rem 0 0 !important;

			h4,
			h5 {
				font-size: 1.4rem;
			}

			h3 {
				font-size: 1.5rem;
			}

			.guidance-text {
				font-size: 1rem;
				margin: 1rem 0;
				padding: 0 2rem;
			}
		}
	}

	.auth-generic-text {
		padding: 6rem 2rem 0 2rem;
		width: 100vw;

		h4 {
			font-size: 1.5rem;
		}

		p {
			font-size: 1.3rem;
			margin: 1.5rem 0 0;
		}
	}
}

.welcome-text {
	font-size: 2.3rem;
	font-style: normal;
	font-weight: 400;
	text-align: center;
}

@media print {
	.backup-codes {
		background-color: #FFFFFF;
	}

	.header,
	.btns,
	.btn-submit {
		display: none;
	}
}