.user-status-chart {
    display: flex;
    justify-content: center;
    align-items: center;
}
.user-action{
    background-color: #F6FBFF;
    color:blue;
    border-radius: 0.2rem;
    padding:0.5rem;
    border:none;
    display: flex;
    justify-content: center;
    align-items: center;
}
.chart-heading{
    padding-top: 25px;
    font-size: 16px;
    font-weight: 600;
    color: #28397F;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    p{
        font-size:12px;
        font-weight:400;
        margin-bottom:0rem;
    }
}

  .apexcharts-yaxis text, .apexcharts-xaxis text{
    fill:#28397F !important ;
    font-size: 12px;
    font-weight: 600;
}
.card {
    .apexcharts-legend-text{
        color: #28397F !important;
        font-size: 14px !important;
        font-weight: 600 !important;
    }
}
.user-status-chart{
    .card{
        height: 410px;
        border: 0.5px solid  #C5C5C5;
    }
    .no-data {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        color: #28397F;
        font-weight: 600;
    }
    .apexcharts-grid-borders {
        .apexcharts-gridline:first-child {
            stroke: none;
        }
    }
    .apexcharts-xaxis-tick {
        stroke: none;
    }
}
