@import '../config/variables';

.accordion-1 {
  background-color: white;
  margin-bottom: 15px;
  border-radius: 5px;
}

.btn-1 {
  background-color: white;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
}
.btn-1:hover,
.collapse-open {
  border-radius: 5px;
  box-shadow: 0px 3px 1px 0px #c5c5c5;
  background-color: #a7d6f3;
  .accordion-icon {
    background-color: #28397f;
  }
}
.heading-col2{
  color: #28397F;
}
.heading-col1 {
  font-size: 14px;
  color: #28397f;
  font-weight: 600;
  span {
    color: #0fbec9;
  }
}
.heading-row {
  font-size: 18px ;
  color: #28397f;
  font-weight: 400;
  span {
    color: #0fbec9;
  }
}
input {
  border-radius: 5px;
}
.generate-invoice-label {
  color: #28397F;
  font-weight: 600;
}
.invoice-btn {
  border-radius: 50%;
  background-color: #0FBEC94D;
  border-color: #28397F;
  padding: 4px;
  height: 1.5rem;
  width: 1.5rem;
  line-height: normal;
  .ri-add-line {
    color: #28397F;
  }
}
.invoice-danger-btn {
  border-radius: 50%;
  background-color: #F8BDC5;
  border-color: #28397F;
  padding: 4px;
  height: 1.5rem;
  width: 1.5rem;
  line-height: normal;
  .ri-subtract-line {
    color: #28397F;
  }
}
.generate-invoice {
  .react-modal-select__placeholder {
    font-size: 1rem;
  }
  .react-modal-select__indicators{
    svg{
        color: #28397f;
    }
  }
  .react-modal-select__menu {
    background-color: #ffffff !important;
    .react-modal-select__menu-list {
      border-bottom: 0.2px solid $konark-secondary-color;
      .react-modal-select__option {
        &:hover {
          background-color: $select-option-bg-color;
        }
      }
    }
  }
  .datepicker-icon {
		width: 1.3rem;
		height: 1rem;
		img {
			width: 100%;
		} 
	}
  input {
    border: 0.5px solid #C5C5C5;
  }
}
.grand-total {
  background-color: #F0F8FF;
}
.prev-btn {
  margin-right: 1rem;
}
.next-btn {
  background-color: #0fbec9;
  border-color: #0fbec9;
}
.view-pdf {
  .modal-header,
  .modal-footer {
      color: #28397f;
      background-color: #fff;
  }
}
.accordion-icon {
  background-color: #1f98a6;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accordion-collapse,
.accordion-item {
  background-color: white;
}
.accordion-collapse {
  .accordion-item {
    border-top: 0.5px solid #b8b8b8;
  }
}
.accordion-body {
  border: 0.5px solid #c4c4c4;
  border-radius: 5px;
  padding: 0px;
  margin-bottom: 10px;
  .acc-heading {
    padding: 10px;
  }
}
.nesting2-accordion {
  .accordion-button {
    background-color: white;
  }
}
.accordion-button::after {
  display: none;
}

.heading-col2 {
  font-weight: 600;
  font-size: 14px;
  color: #615d5d;
  display: flex;
  align-items: center;
  line-height: 19px;
}
.heading-col3 {
  font-weight: 600;
  font-size: 14px;
  color: #615d5d;
}

.react-datepicker-wrapper {
  width: 100%;
}

.acc-icon {
  font-size: xx-large;
  color: white;
  padding: 3px;
}
.nesting2-accordion {
  .btn-2:hover,
  .collapse-open2 {
    background-color: #f6fbff;
  }
}
.submit-button {
  padding: 6px 32px 6px 32px;
}

.select-box-size {
  margin-right: 10px;
}

.arrow-icon {
  color: #28397f;
  font-size: x-large;
}
.table-2-head {
  font-size: 14px;
  font-weight: 400;
  color: #28397f;
}
.pie-chart-heading {
  align-items: center;
  display: flex;
  color: #28397f;
  justify-content: center;
  padding: 20px;
  font-size: 18px;
  font-weight: 600;
}
.tab-content {
  border-bottom: 0.5px solid #c4c4c4;
  padding: 0px;
  span {
    font-weight: 600;
  }
}
.border-right {
  border-right: 0.5px solid #28397f;
}
.report-col {
  .hstack {
    flex-shrink: initial;
  }
}

.action-button {
  background-color: #28397f;
  padding: 0px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  span {
    color: white;
    font-size: 12px;
    font-weight: 400;
  }
}
.report-filter-section {
  .react-modal-select__multi-value{
		background-color: #0FBEC915 ; 
		color: #0FBEC9;
		border-radius: 25px;
	}
	.css-wsp0cs-MultiValueGeneric{
		color: inherit;
	}
  .react-modal-select__placeholder {
    font-size: 1rem;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-modal-select__indicators{
    svg{
        color: #28397f;
    }
  }
  .css-1s2u09g-control {
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
    
  }
  .react-modal-select__menu {
    background-color: #ffffff !important;
    .react-modal-select__menu-list {
      border-bottom: 0.2px solid $konark-secondary-color;
      .react-modal-select__option {
        &:hover {
          background-color: $select-option-bg-color;
        }
      }
    }
  }
}
.table>:not(caption)>*>*{
 padding:  0.75rem 1.6rem
}
.report-filter-section{
.auth-pass-inputgroup{
    img{
      height: 1.5rem;
    margin-top: 0.15rem;
    }

  }
}
.checkbox-tab .checkbox-selected{
  height: 1.2rem;
  width: 1rem;
}
/* Mobile View */
@media screen and (max-width: 767px) {
  .report-filter-section {
    .auth-pass-inputgroup {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 10px;
    }
  }
  .accordion-button{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
  }

  .accordion-1{
    .accordion-header{
    padding-left: 0px !important;
    padding-right: 0px !important;
    }
    .checkbox-tab{
      flex-basis: 70%;
      order: 1;
    }
    
    .total-hours{
      flex-basis: 36%;
      order: 4;
      margin-top: 10px;
    }
    .accordion-icon{
      order: 2;
    }
    .client-name{
      flex-basis: 50%;
      order: 3;
      justify-content: space-between;
      display: flex;
    }  
  }

  .client-name {
    margin-top: 10px;
    width: 100%;
  }

  .total-hours {
    width: 100%;
  }

  .heading-col1,
  .heading-col2,
  .heading-col3 {
    font-size: 12px;
    text-align: center;
  }

  .table-2-head {
    font-size: 12px;
    text-align: center;
  }

  .pie-chart-heading {
    padding: 10px;
    font-size: 16px;
    text-align: center;
  }

  .tab-content {
    padding: 0;
  }

  

  .border-right {
    border-right: none;
  }

  .border-bottom {
    border-bottom: none !important;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .btn-1,
  .btn-2 {
    width: 100%;
    margin-bottom: 10px;
  }

  .checkbox-tab .checkbox-selected{
    height: 1.2rem;
    width: 1rem;
  }

  .submit-button {
    width: 100%;
    margin-bottom: 10px;
  }
  
}
.scrollable-table {
  overflow-x: auto;
}

.description{
  p{
    margin-bottom: 2px
  }
}

@media screen and (max-width: 767px) {
  .accordion-collapse {
    width: 100%;
  }
  .table-responsive {
    width: 100%;
    overflow-x: auto;
  }

  .table-nowrap th {
    width: 100px; 
  }

  .table-nowrap td,
  .table-nowrap th {
    white-space: nowrap;
    text-align: center;
  }
}
