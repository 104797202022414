html[data-darkmode='true']{
    .btn-primary {
        background-color: #fff !important;
        color: $konark-primary-color !important;
    }
    .btn-secondary{
        background-color: #2A2C38 !important;
        border: 0.5px solid #615D5D !important;
        &:hover{
            background-color: #1F1F1F !important;
            border: 0.5px solid #615D5D !important;
        }
        &.focus,
        &:focus {
            box-shadow: 0 0 0 0.2rem #615D5D80 !important;
        }
    }
    .text-primary {
        color: #fff !important;
    }
    body{
        background-color: #2A2C38; 
        color: #fff;
    }
    #layout-wrapper{
        .dropdown-avatar-tab.download{
            .dropdown-avatar-btn{
                svg{
                    line{
                        stroke: #fff;
                    }
                    path,
                    rect{
                        fill:#fff
                    }
                }
                span{
                    color: #fff !important;
                }
            }
            .dropdown-menu{
                svg{
                    path{
                        fill: #fff;
                    }
                }
            }
        }
        .dropdown-avatar {
            .avatar-right {
                .designation,
                p {
                    color: #fff;
                }
            }
            .down-arrow {
                i {
                    color: #fff;
                }
            }
        }
        header {
            color: #fff;
            background-color: #2A2C38;
            border: 1px solid #615D5D;
            button.light-dark-mode {
                i {
                    color: #fff;
                }
            }
            .primary-dropdown-btn{
                .toggle-btn{
                    background-color: #fff;
                    color: $konark-primary-color;
                }
                svg{
                    rect,
                    path{
                        fill:$konark-primary-color
                    }
                    line{
                        stroke: $konark-primary-color;
                    }
                }
                .dropdown-menu{
                    background-color: #fff !important;
                    border: 0.5px solid #c5c5c5 !important;
                    .dropdown-item{
                        background-color: #fff !important;
                        color: #fff;
                        span{
                            color: #212529;
                        }
                    }
                }
            }
            .manual{
                svg{
                    path{
                        fill: #fff;
                    }
                }
                &:hover{
                    svg{
                        path{
                            fill: #0B83ED;
                        }
                    }
                }
            }
        }
       .project-card-container .project-card-body .name,
       .projects-clients-card .client-card-body .name {
            color: #fff;
        }
        .clients-card, .card-body {
            border: 0.3px solid #615D5D;
        }
        .project-card-container{
            color: #fff;
            .project-card-details{
               .manager-section,.team-section,.productivity-section{
                   span {
                       color:#fff !important;
                   }
                   .txt-warning{
                    color: #876A02 !important;
                   }
                   .txt-danger{
                    color: #D71509 !important;
                   }
                   .txt-success {
                    color: $konark-success-color !important;
                }
               }
                
            }
        }
        .app-menu {
            background-color: #2A2C38;
            border: 1px solid #615D5D;
            .menu {
                i{
                    color: #fff;
               }
            }
            .menu-link {
                svg{
                    path{
                        fill:#fff
                    }
                }
                &.reports{
                    svg{
                        path:last-child {
                            fill:#2A2C38
                        }
                    }
                }
                span {
                    color: #fff;
                }
            }
            .menu-link:hover,
            .menu-link.active {
                svg{
                    path{
                        fill:#0B83ED
                    }
                }
                span {
                    color: #0B83ED;
                }
            }
        }
        
        .page-content{
            .txt-warning {
                color: #876A02 !important;
            }
            .badge-warning {
                background-color: #F1DB4E !important;
            }
            .text-muted {
                color: #c4c4c4 !important;
            }
            .formfields,
            .blockquote,
            .tagline,   
            .heading-div{
                color: #fff;
            }
            .setting-nav-card{
                border-radius: 10px;
                .nav-link.active{
                    background-color: #1F1F1F;
                    border-left: thick solid #0fbec9;
                    border-radius: 0;
                    color: #fff;
                }
            }
            .heading-col3 {
                color: #9b9b9b;
            }
            .setting-card{
                border-radius: 10px;
                .main-card{
                    margin-left: 0px !important;
                }
                .nav-card{        
                    border: none;
                }
                .nav-card-header{
                    border: none;
                    border-radius: unset;
                    border-bottom: 1px solid #615D5D;
                }
                .card-body{
                    border: none;
                }
                input{
                    border: 1px solid #615D5D;
                }
            }
            input{
                caret-color: #fff;
                color: #fff;
                &::placeholder{
                    color: #f2f2f2;
                }
            }
            .filter-styles, iframe, embed {
                .css-tlfecz-indicatorContainer:hover,
                .css-tlfecz-indicatorContainer{
                    color: #00000000;
                }
                .react-select__menu{
                    .react-select__menu-list {
                        background-color: #2A2C38;
                        border: 0.5px solid #615D5D;
                        .react-select__option {
                            border-bottom: none;
                            &:hover {
                                background-color: #1F1F1F !important;
                            }
                        }
                        .react-select__option--is-selected, .react-select__option--is-focused{
                            background-color:  #1F1F1F !important;
                        }
                    }
                }
            }
            .dashboard-card{
                .activity-card{
                    &:hover{
                        background-color: #1F1F1F !important; 
                    }
                }
            }
            .most-used-app {
                background-color: #A2DFCE;
            }
            .view-all {
                color: #c4c4c4;
            }
            .table-data {
                color: #fff;
            }
            .company-profile {
                .cp-text {
                    color: #fff;
                }
                label {
                    color: #c4c4c4;
                }
            }
            .dashboard-select {
                border: 0.5px solid #615D5D !important;
                select {
                    color: #fff;
                    background-color: #2A2C38;
                    option {
                        color: #fff;
                        &:hover {
                            background-color: #1F1F1F !important;
                        }
                        background-color: #2A2C38;
                    }
                }
            }
            .activity-card{
                .card-body{
                    .card,
                    .card-body{
                        border: none;
                    }
                }
                .weekly-time{
                    background-color:  rgba(11, 131, 237, 0.60);
                    border-radius: 5px;
                    svg{
                        path{
                            fill: #F2F2F2;
                        }
                    }
                }
                .monthly-time {
                    border-radius: 5px;
                    background: #F6FBFF;
                }
            }
            .client-card{
                .card{
                    &.card-project-user-least {
                        border-left: 0.5px solid #615D5D !important;
                    }
                    border: 0px solid #00000000 !important;
                    .card-body,
                    .card-header{
                        border: 0px solid #00000000 !important;
                    }
                }
            }
            .upcomming-event{
                .date-badge{
                    color: #fff;
                }
            }
            .export-tab{
                color: #fff;
            }
            .nav-link{
                &.active{
                    background-color: #fff;
                    color: #28397f;
                }
                color: #fff;
            }
            .border-right {
                border-right: 0.5px solid #fff;
            }
            .activity-card .text-muted,
            .activity-card h5,
            .i-logo,
            .number{
                color: #fff !important;
            }
            .card-header,
            .card-body,
            .card{
                background-color: #2A2C38 !important; 
                border: 0.3px solid #615D5D;
                color: #fff;
            }
            .card-body{
                border: none;
            }
            .card-header{
                border-bottom: 0.3px solid #615D5D;
                border-top:0;
                border-left: 0;
                border-right: 0;
            }
            .reports-card{
                .card-header,
                .card-body{
                    border: none;
                }
                border: none;
                th{
                    border-bottom: 1px solid #615D5D !important;
                }
                tr{
                    border-bottom: 0.5px solid #615D5D !important;
                }
            }
            .invoice-table {
                border: 0.5px solid #615d5d;
                tbody tr:nth-child(odd) {
                    background-color: #1F1F1F;
                    color: #fff;
                }
                thead {
                    tr {
                        border: 0.5px solid #615d5d !important;
                        background-color: #2A2C38;
                        th {
                            color: #fff;
                        }
                    }
                }
                tr:last-child {
                    border-bottom: 0.5px solid #615d5d !important;
                }
                .invoice-id-text {
                    color: #fff;
                    text-decoration-color: #fff;
                }
            }
            .invoice-btn {
                background-color: #0FBEC9;
                .ri-add-line {
                  color: #1F1F1F;
                }
              }
            .invoice-danger-btn {
                .ri-subtract-line {
                  color: #1F1F1F;
                }
            }
            .heading-row {
                color: #c4c4c4;
                span {
                  color: #fff;
                }
            }
            .generate-invoice-label {
                color: #fff;
            }
            .generate-invoice {
                .react-modal-select__indicators{
                  svg{
                      color: #fff;
                  }
                }
                .react-modal-select__control--is-focused,
                .react-modal-select__control--menu-is-open,
                .react-modal-select__control{
                    background-color: #2A2C38 !important;
                    border: 1px solid #615D5D !important;
                }
                .react-modal-select__menu {
                    background-color: #2A2C38 !important;
                    border: 1px solid #615D5D !important;
                    .react-modal-select__menu-list {
                        border-bottom: 0.2px solid #615D5D;
                        .react-modal-select__option {
                            background-color: #2A2C38;
                            &:hover {
                                background-color: #1F1F1F;
                            }
                        }
                        .react-modal-select__option--is-selected, .react-modal-select__option--is-focused{
                            background-color:  #1F1F1F !important;
                        }
                    }
                }
                .react-modal-select__value-container .react-modal-select__single-value,
                .react-modal-select__placeholder{
                    color: #fff;
                }
                input {
                  border: 0.5px solid #615D5D;
                }
            }
            .grand-total {
                background-color: #2A2C38;
            }
            .view-pdf {
                .modal-header,
                .modal-footer {
                    color: #fff;
                    background-color: #2A2C38;
                }
            }
            .table-special{
                thead{
                    background-color: #2A2C38;
                    color: #fff;
                }
            }
            .card-border-top {
                border: none;
                border-top: 0.5px solid #615D5D;
            }
            .screenshot-table-header {
                border: 0.5px solid #615D5D;
            }
            table{
                tr{
                    border-bottom: 0.5px solid #00000000;
                }
            }
            .table-heading{
                background-color: #2A2C38;
                border: none;
                color: #fff;
            }
            .table-striped {
                tbody tr {
                    background-color: #1F1F1F;
                    td {
                        color: #fff;
                    }
                }

                tbody tr:nth-child(even) {
                    background-color: #2A2C38;
                    color: #fff;
                }
            }
            .table-2-head{
                color: #fff !important;
            }
            .accordion-button.btn-2{
                i{
                    color: #fff;
                }
            }
            .accordion-body{
                border: 0.5px solid #615D5D;            
            }
            .accordion-button{
                &.collapse-open,
                &:hover{
                    box-shadow: 0px 3px 1px 0px #615D5D;
                }
            }
            .table{
                color: #fff;
            }

            .btn-1{
                background-color: #2A2C38;
                border: 1px solid #615D5D;
            }
            .heading-col2,
            .heading-col1{
                color: #fff;
            }
            .chart-heading,
            .tagline-text,
            .fc-toolbar-title{
                color: #fff;
            }
            .dot-icon{
                color: $konark-info-color;
            }
            .fc-scrollgrid{
                tr{
                    border: 0 !important;
                }
            }
            .fc .fc-col-header-cell .fc-col-header-cell-cushion{
                background-color: #2A2C38 !important;
                color: #fff !important;
            }
            .fc-scrollgrid.table-bordered.fc-scrollgrid-liquid{
                background-color: #2A2C38 !important;
                color: #fff !important;
            }
            .fc .fc-day{
                border: 0.3px solid #615D5D;
            }
            .fc .fc-col-header-cell .fc-scrollgrid-sync-inner{
                border-bottom: 0.3px solid #615D5D;//9b9b9b
            }
            .fc .fc-today-button.btn:not(:disabled){
                font-weight: 600;
            }
            .fc-day-today .fc-daygrid-day-number{
                background-color: $konark-info-color;
            }
            .fc .week-offs{
                background-color: #1F1F1F !important;
            }
            .fc .fc-scrollgrid-sync-table .fc-daygrid-day-frame .fc-daygrid-day-top .fc-daygrid-day-number{
                color: #fff !important;
            }
            .fc .fc-view-harness{
                border: 0.5px solid #615D5D;
                border-radius: 0.4rem;
            }
            .fc .fc-col-header-cell{
                background-color: #9b9b9b;
            }
            .apexcharts-yaxis text, .apexcharts-xaxis text{
                fill:#fff !important;
            }
            .card .apexcharts-legend-text {
                color:#fff !important;
            }
            .apexcharts-slices{
                .apexcharts-pie-series {
                    path {
                        stroke: #2A2C38;
                    }
                }
            }
            .screenshot-container {
                .screenshot-hour {
                    color: #fff;
                }
                .screenshot-item {
                    p {
                        color: #fff !important;
                    }
                }
            }
            .screenshot-container-header {
                border-bottom: 1px solid #615D5D;
                border-top: 1px solid #615D5D;
            }
            .user-status-chart .no-data {
                color: #fff;
            }
            .pagination-styles {
                .pagination-btn {
                    border: 0.5px solid #615D5D;
                    background-color: #2A2C38;
                    color: #fff;
                    &:hover{
                        background-color: #1F1F1F;
                    }
                    &:active{
                        background-color: #1F1F1F;
                    }
                }
                .page-entries {
                    color: #fff;
                }
                padding: 0.5rem;
            }
            .generic-accordion {
                color: #fff;
                border: 1px solid #615D5D !important;
                background-color: #2A2C38;
                .accordion-header {
                    background-color: #2A2C38;
                }
                .accordion-collapse {
                    background-color: #2A2C38;
                    .collapse-group:nth-child(odd) {
                        background-color: #1F1F1F;
                    }
                }
            }
            .tab-content {
                border-bottom: none;
            }
        }
        .dropdown-menu{
            background-color: #2A2C38 !important;
            border: 0.5px solid #615D5D;
            .dropdown-item{
                background-color: #2A2C38 !important;
                span{
                    color: #fff;
                }
            }
        }
        .dropdown-avatar-tab {
            .dropdown-menu-end.options {
                span{
                    color: #fff;
                }
            }
        }
        .pie-chart-heading,
        .dropdown-item{
            color: #fff;
        }
        .user-action{
            background-color: #1F1F1F;
            color:#fff;
            border: 1px solid #615D5D;
        }
        .user-dashboard {
            .project-overview {
                .card{
                    background-color: #1F1F1F !important;
                    border: 0 !important;
                }
                .card-col{
                    background-color: #1F1F1F !important;
                    border: 0 !important;
                }
                .avatar-text{
                    color: #000 !important;
                }
                h4{
                    color: #fff;
                }
                h5 {
                    color: #fff;
                    
                }
                .np-count {
                    color: #fff;
                    
                }
            }
            .projects-graph {
                .apex-charts {
                    .apexcharts-legend-text {
                        color: #fff !important;
                        font-weight: 700;
                    }
                    .apexcharts-legend {
                        top: 5px !important;
                    }
                    .apexcharts-yaxis,
                    .apexcharts-xaxis {
                        text {
                            fill: #fff !important;
                            font-weight: 700;
                        }
                    }
                }
            }
            .daily-goal{
                .arrow,
                .header-date{
                    color: #fff;
                }
            } 
            .team-card {
                border: none !important;
                .project-header {
                    background-color: #2A2C38 !important;
                    border-bottom: 0.5px solid #615D5D;
                    border-top: none;
                    border-left: none;
                    border-right: none;
                    .search-box{
                        background-color: #2A2C38 !important;
                        border: 0.5px solid #615D5D !important;
                    }
                }
                .project-list {
                    border: none !important;
                    .card-body {
                        border: none !important
                    }
                    .accordion-section {
                        .generic-accordion.accordion-item  {
                                color: #fff !important;
                                background-color: #1F1F1F !important;
                                .accordion-collapse {
                                    background-color: #2A2C38 !important;
                                    .collapse-group:nth-child(odd) {
                                        background-color: #2A2C38 !important;
                                    }
                                }
                                .accordion-header {
                                    background-color: #1F1F1F !important;
                                }
                            }
                    }
                    .project-card-container .project-card-body .name {
                       color: #fff !important;
                    }
                }
            }
            .ud-project-list{
                thead{
                    background-color: #2A2C38 !important;
                }
            }

        }
        .accordion-collapse,
        .accordion-item,
        .accordion-1 {
            background-color: #2A2C38;
        }
        .accordion-collapse {
            .accordion-item {
                border-top: 0.5px solid #615D5D;
            }
        }
        .accordion-body {
            border: 0.5px solid #615D5D;
        }
        .nesting2-accordion {
            .btn-2:hover,
            .collapse-open2 {
                background-color: #000 !important;
            }
            .accordion-button {
                background-color: #2A2C38;
            }
        }
        .btn-1:hover,
        .collapse-open {
            .accordion-icon {
                background-color: #fff;
            }
            .acc-icon {
                color: #28397f;
            }
        }
    }

    .view-projects{
        .type-project {
            color: #28397f !important;
        }
        .project-profile-card{
            h5{
                color: #fff;
            }
            p{
                color: #878a99 !important
            }
        } 
        .about-project,.projects-clients-card{
            h6{
                color: #878a99 !important
            }
            span,p{
                color: #fff;
            }
        }
    }
    .view-user {
        .card-body {
            h6 {
                color: #fff;
            }
        }
        .team-card {
            .badge-info {
                background-color: #0fbec9;
            }
        }
    }
    .view-client {
        .card-body {
            h6 {
                color: #fff;
            }
        }
        .nav-link.not-admin-view-client {
            background: #2a2c38 !important;
            color: #fff !important;
            border-bottom: none;
        }
    }
    .view-client,
    .view-projects,
    .view-user {
        table{
            tbody:not(.projects-table), thead{
                tr{
                    border-bottom: 0.5px solid #615D5D !important;
                }
            }
        }
        .table-scroll::-webkit-scrollbar-thumb {
            background-color: #fff;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 3px;
        }
    }
    .popOverbody{
        font-size:14px;
        font-family:'Open Sans';
        color:#fff;
        line-height:20.62px;
        padding: 1rem 1rem 0 0;
    }
    .popover{
        background:#2a2c38;
        border:#615D5D solid 0.5px;
        border-radius:5px
    }
    .popOverbody li::marker{
        color: #fff;
        font-size: 14px;
    }
    .popOverbody ul::-webkit-scrollbar-thumb{
        background-color: #fff !important;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
        border-radius: 3px !important;
    }
    .react-modal-select__indicators{
        svg{
            color: #fff;
        }
      }
    .react-modal-select__control--is-focused,
    .react-modal-select__control--menu-is-open,
    .react-modal-select__control{
        background-color: #2A2C38 !important;
        border: 1px solid #615D5D !important;
    }
    .react-modal-select__menu {
        background-color: #2A2C38 !important;
        border: 1px solid #615D5D !important;
        .react-modal-select__menu-list {
            border-bottom: 0.2px solid #615D5D;
            .react-modal-select__option {
                background-color: #2A2C38;
                &:hover {
                    background-color: #1F1F1F;
                }
            }
            .react-modal-select__option--is-selected, .react-modal-select__option--is-focused{
                background-color:  #1F1F1F !important;
            }
        }
    }
    .react-modal-select__value-container .react-modal-select__single-value,
    .react-modal-select__placeholder{
        color: #fff;
    }
    .react-modal-select__menu-list::-webkit-scrollbar-thumb {
        background-color: #fff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 3px;
    }
    .tags {
        .table-view {
            border: 0.5px solid #615D5D !important;
        }
    }
    .quill {
        .ql-picker-label {
          color: $konark-dark-mode-text-color;
          border-color: $konark-dark-mode-border-color !important;
        }
    
        .ql-toolbar,
        .ql-container {
          border: 1px solid $konark-dark-mode-border-color;
        }
    
        svg .ql-stroke {
          stroke: $konark-dark-mode-text-color;
        }
    
        svg .ql-fill {
          fill: $konark-dark-mode-text-color;
        }
    
        .ql-editor.ql-blank::before {
          color: $konark-dark-mode-text-color;
        }
    
        .ql-picker-options,
        .ql-tooltip {
          background: $konark-dark-mode-background-color;
          color: $konark-dark-mode-text-color;
          box-shadow: 0 0 5px $konark-dark-mode-border-color !important;
          border: 1px solid $konark-dark-mode-border-color !important;
    
          .ql-picker-label {
            border-color: $konark-dark-mode-border-color !important;
          }
    
          input {
            background: $konark-dark-mode-background-color;
            color: $konark-dark-mode-text-color;
            border: 1px solid $konark-dark-mode-border-color !important;
          }
    
          p:focus-visible,
          input:focus-visible {
            outline: none !important;
          }
        }
    }
   
    .skeleton-box {
        background-color: #2A2C38 ;
        animation: skeleton-dark-loading 2s linear infinite alternate;
      }    
      @keyframes skeleton-dark-loading {
    	  0% {
    		background-color: #48464a;
    	  }
    	  100% {
    		background-color: #383838;
    	  }
    }
    .filter-styles{
        .react-select__control{
            background-color: #2a2c37;
            border: 1px solid #615D5D !important;
            .react-select__value-container .react-select__single-value,
            .react-select__placeholder{
                color: #fff;
            }
        }
        .react-select__menu-list {
            background-color: #2a2c37;
            border: 0.5px solid #615D5D;
            .react-select__option {
                border-bottom: none;
                &:hover {
                    background-color: #2a2c37;
                }
            }
            .react-select__menu-item:hover {
                background-color: #1F1F1F;
                color: #fff;
            }
        }
        .react-select__menu-item:hover {
            background-color: #1F1F1F;
            color: #fff;
        }
        .react-select__control--is-focused,
        .react-select__control--menu-is-open {
            box-shadow: 0 0 0 0.2rem #615D5D80 !important;
        }
        .react-select__indicators {
			svg {
				path {
				    fill: #fff;
				}
			}
		}
    }
    .bread-crum-back-button{
        g{
            path{
                fill:#1F1F1F
            }
        }
        path{
            fill: #fff;
        }
    }
    .grid-list-btn {
        path{
            fill:#fff;
        }
    }
    .report-filter-section {
        .react-modal-select__multi-value{
            background-color: #28397f ; 
            color: #ffffff;
            border-radius: 25px;
        }
        .css-wsp0cs-MultiValueGeneric{
            color: inherit;
        }
        .morePopOver{
            color: #fff;
            float: right;
            margin-left: auto;
            background-color: #0FBEC9;
            border-radius: 10px;
            font-family: "Open Sans";
            font-size: 11px;
            padding: 4px;
            order: 99;
            position: absolute;
			top: 3px;
			right: 0;
        }
        .react-modal-select__indicators{
          svg{
              color: #fff;
          }
        }
        .react-modal-select__control--is-focused,
        .react-modal-select__control--menu-is-open,
        .react-modal-select__control{
          background-color: #2A2C38 !important;
          border: 1px solid #615D5D !important;
        }
        .react-modal-select__menu {
          background-color: #2A2C38 !important;
          border: 1px solid #615D5D !important;
          .react-modal-select__menu-list {
            border-bottom: 0.2px solid #615D5D;
            .react-modal-select__option {
                background-color: #2A2C38;
              &:hover {
                background-color: #1F1F1F;
              }
            }
            .react-modal-select__option--is-selected, .react-modal-select__option--is-focused{
                background-color:  #1F1F1F !important;
            }
          }
        }
        .react-modal-select__value-container .react-modal-select__single-value,
        .react-modal-select__placeholder{
            color: #fff;
        }
    }
    .modal-content{
        background-color: #2A2C38;
        .modal-header,
        .modal-body{
            background-color: #2A2C38;
        }
        .active-window{
            color:white;
            border-bottom: 2px solid white !important;
        }
    }

    .datepicker-icon{
        path{
            fill: #fff;
        }
    }
    .search-box{
        border: 0.5px solid #615D5D;
    }
    .field-border {
        border: 0.5px solid #615D5D;
    }
    .react-datepicker{
        border: 1px solid #615D5D;
    }
    .react-datepicker__month-select,
    .react-datepicker__year-select,
    .react-datepicker__day-name, 
    .react-datepicker__day, 
    .react-datepicker__time-name,
    .react-datepicker__current-month, 
    .react-datepicker-time__header, 
    .react-datepicker-year-header{
        color: #fff;
    }
    .react-datepicker__day--disabled {
        color: #ffffff80;
        &:hover {
            background-color: #2A2C38 !important;
        }
    }
    .react-datepicker__day:hover{
        background-color: #1F1F1F;
    }
    .react-datepicker__day--keyboard-selected{
        background-color: #615D5D;
    }
    .react-datepicker__month-container{
        background-color: #2A2C38 !important;
        .react-datepicker__header{
            background-color: #2A2C38 !important;
            border-bottom:0 !important;
            color: #fff;
            select{
                border: none !important;
                background-color: #2A2C38 !important;
            }
        }
        .react-datepicker__month{
            background-color: #2A2C38 !important;
        }
    }
    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after{
        border-top-color: #615D5D !important;
    }
    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
        border-bottom-color: #615D5D !important;
    }
    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before{
        border-top-color: #615D5D !important;
    }
    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before{
        border-bottom-color: #615D5D !important;
    }
    .filter-modal-body .card-body{
        background-color: #2A2C38;
    }
    input, textarea {
        color: #fff !important;
    }
    .multiple-field-form{
        label{
            color: #fff;
        }
        .multiple-file-field {
            border: 0.5px solid #615D5D;
            .multiple-file-field-label{
                background-color: #1F1F1F;
            }
            .text-muted{
                color: #fff !important;
            }
        }
    }
    .add-new-modal{
        .termsconditions-col{
            label{
                color:#fff;
            }
        }
        .filter-modal-body{
            background-color: #2A2C38 !important;
            border-bottom: 1px solid #615D5D !important;
        }
        .modal-body {
            .morePopOver{
                color: #fff;
                float: right;
                margin-left: auto;
                background-color: #0FBEC9;
                border-radius: 10px;
                font-family: "Open Sans";
                font-size: 11px;
                padding: 4px;
                order: 99;
                position: absolute;
                top: 3px;
                right: 0;
            }
            .react-modal-select__multi-value{
                background-color: #28397f ; 
                color: #ffffff;
                border-radius: 25px;
            }
            .css-wsp0cs-MultiValueGeneric{
                color: inherit;
            }
            .multiple-field-form{
                label{
                    color: #fff;
                }
                .multiple-file-field {
                    border: 0.5px solid #615D5D;
                    .multiple-file-field-label{
                        background-color: #1F1F1F;
                    }
                    .text-muted{
                        color: #fff !important;
                    }
                }
            }
            input, 
            textarea {
                border: none;
                border-bottom: 1px solid #615D5D;
                caret-color: #fff;
                color: #fff;
                &::placeholder{
                    color: #9b9b9b !important;
                }
            }
            
            .sample-file-btn{
                background-color: #2A2C38 !important;
                color: #ffffff !important;
                svg{
                    path{
                        fill:#fff;
                    }
                    line{
                        stroke: #fff;
                    }
                }
            }
            .weekoff-options {
                input[type='checkbox'] {
                    &:checked {
                        accent-color: $konark-primary-color;
                    }
                }
                .entry {
                    span {
                        color: #fff;
                    }
                }
            }
            .react-modal-select__indicators{
                svg{
                    color: #fff;
                }
            }
            .react-modal-select__control--is-focused,
            .react-modal-select__control--menu-is-open,
            .react-modal-select__control{
                background-color: #2A2C38 !important;
                border-bottom: 1px solid #615D5D !important;
            }
            .react-modal-select__menu {
                background-color: #2A2C38 !important;
                border-bottom: 1px solid #615D5D !important;
                .react-modal-select__menu-list {
                    border-bottom: 0.2px solid #615D5D;
                    .react-modal-select__option {
                        background-color: #2A2C38;
                        &:hover {
                            background-color: #1F1F1F !important;
                        }
                    }
                    .react-modal-select__option--is-selected, .react-modal-select__option--is-focused{
                        background-color:  #1F1F1F !important;
                    }
                }
            }
        }
        .react-modal-select__value-container .react-modal-select__single-value,
        .react-modal-select__placeholder{
            color: #fff;
        }
        .time-log-header {
            border-bottom: 1px solid #c5c5c5;
            color: #fff;
        }
        
    }
}