@mixin avatar-log-or-text {
	z-index: 100;
	border-radius: 50%;
	padding: 4px 10px;
	background: #b6d1e8;
}
.view-projects{
	.card-body.card-body-border {
		border-top: 0.5px solid #C5C5C5;
	}
	.card-header.card-header-border{
		border-bottom: 0.5px solid #C5C5C5;
	}
}
.bg-soft-success {
	background-color: #D1E7DD;
}
.bg-soft-warning {
	background-color: #FFF3CD;
}
.bg-soft-info {
	background-color: #CFE2FF;
}
.bg-soft-danger {
	background-color: #F8D7DA;
}
.invoice-table {
	.invoice-id-text {
		color: #0fbec9;
		text-decoration: underline;
		text-decoration-color: #0fbec96e;
	}
	a {
		text-decoration: none;
	}
	thead {
		tr {
			background-color: #ffffff;
			border-top: 0.5px solid #c5c5c5;
		}
	}
	tr {
		td,
		th {
			padding-top: 1.2rem;
			padding-bottom: 1.2rem;
		}
		th {
			color: #615d5d;
			padding-top: 1.2rem;
			padding-bottom: 1.2rem;
		}
	}
}

.field-border {
	border: 0.5px solid #C5C5C5;
}
.add-team-modal {
	display: flex;
	a {
		text-decoration: none;
	}
	.avatar-xxs {
		text-align: center;
		text-decoration: none;
		.avatar-title {
			color: $project-avatar-text-color;
			height: 1.6rem;
			width: 1.6rem;
			border: $project-avatar-border;
			margin-right: -0.3rem;
		}
	}
	.simplebar-placeholder {
		height: 0% !important;
	}
	.user-img,
	.logo {
		height: 2rem;
		width: 2rem;
		text-align: center;
		line-height: 2rem;
	}
	.modal-content {
		background-color: #ffffff;
	}
	.table-scroll {
		max-height: 245px !important;
		overflow: auto;
	}
	.table-scroll::-webkit-scrollbar {
		width: 6px;
	}
	.table-scroll::-webkit-scrollbar-thumb {
		background-color: #263780;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius: 3px;
	}
	.table-scroll::-webkit-scrollbar-track {
		background-color: #f2f2f2;
	}
	.table-scroll::-webkit-scrollbar {
		width: 8px;
	}
}

#add-team-list {
	.user-logo:nth-child(5n-1) {
		.avatar-title {
			background: #ffb8b8;
		}
	}
	.user-logo:nth-child(5n-2) {
		.avatar-title {
			background: #ada8fa;
		}
	}
	.user-logo:nth-child(5n-4) {
		.avatar-title {
			background: #a2dfce;
		}
	}
	.user-logo:nth-child(5n-3) {
		.avatar-title {
			background: #ecb907;
		}
	}
	.user-logo:nth-child(5n-5) {
		.avatar-title {
			background: #b6d1e8;
		}
	}
}

#layout-wrapper {
	.app-menu {
		position: fixed;
		left: 0%;
		top: 0%;
		height: 100%;
		background: #f6f8fa;
		padding: 14px;
		.menu-link {
			span {
				color: #28397f;
			}
		}
		.menu-link:hover,
		.menu-link.active {
			svg{
				path{
					fill:#0B83ED;
				}
			}
			&.reports{
				svg{
					path:last-child {
						fill:#f6f8fa;
					}
				}
			}
			span {
				color: #0B83ED;
			}
		}
		.menu {
			.header-profile-user {
				height: 2rem;
				width: 2rem;
			}
			display: flex;
			flex-direction: column;
			align-self: flex-start;
			.toggle {
				margin-bottom: 20px;
			}
			i {
				font-size: 20px;
				color: $konark-primary-color;
			}
			a.active {
				i {
					color: #0b83ed;
				}
			}
			gap: 20px;
			.switch-options{
				display: none;
			}
			@media (max-width: 500px) {
				.toggle-arrow {
					transform: rotate(180deg);
					text-align: right;
				}
				.switch-options{
					display: flex;
					flex-direction: column-reverse;
					.switch-user-options{
						border-top: 0.5px solid $konark-primary-color;
						border-bottom: 0.5px solid $konark-primary-color;
						.dropdown-toggle{
							background-color: $konark-sidebar-color;
							width: 100%;
							display: flex;
							align-items: center;
							justify-content: flex-start;
							gap: 0.5rem;
								span{
									font-size:0.9rem;
									color:$konark-primary-color;							
								}
							
							}
						}
					.toggle-theme, .manual{
						display: none;
					}
				}
			}
		}
		ul {
			li {
				span {
					display: none;
				}
			}
		}
		@media(max-width:500px){
			left:unset;
			right:0%;
		}
	}
	header {
		position: relative;
		z-index: 50;
		box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.07);
		background: #fff;
		color: $konark-primary-color;
		height: 64px;
		margin-left: 53px;
		.toggle, .toggle-theme-mobile {
			display: none;
		}
		.navbar-header {
			.switch-options{
				display: flex;
				align-items: center;
			}
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin: 0 auto;
			height: 70px;
			padding: 0 1.5rem 0 calc(1.5rem / 2);
			.navbar-logo {
				height: 64px;
				padding: 13px 0px;
				img {
					height: 100%;
				}
			}
			@media (max-width: 500px) {
				.header-container {
					display: flex;
					width: 100%;
				}
				.navbar-logo {
					display: flex;
					flex-direction: row-reverse;
					align-items: center;
					justify-content: space-between;
					width: 100%;
				}
				.hide {
					display: none !important;
				}
				.toggle-theme-mobile {
					display: block;
				}
			}
		}

		.header-profile-user {
			height: 2.5rem;
			width: 2.5rem;
		}
		button.light-dark-mode {
			padding: 5px;
			i {
				font-size: 20px;
				color: $konark-primary-color;
			}
			&:focus-visible,
			&:focus,
			&:visited,
			&:active {
				border: none;
				box-shadow: none;
			}
		}
		.primary-dropdown-btn {
			.toggle-btn {
				background-color: $konark-primary-color;
				color: #fff;
				padding: 5px;
				display: inline-flex;
				align-items: center;
				span {
					font-weight: 500;
					padding: 0 0.4rem;
				}
			}
			@media (max-width: 500px) {
				.manuals {
					display: none;
				}
			}
			a{
				text-decoration: none;
			}
		}

		.manual:hover{
			svg{
				path{
					fill: #0B83ED;
				}
			}
		}

		.dropdown-menu {
			background-color: #fff;
			border: 0.5px solid #c5c5c5;
			border-radius: 5px;
		}
		.dropdown-divider {
			border-top: 1px solid #c5c5c5;
		}

		@media (max-width: 500px) {
			.header-profile-user {
				height: 1.5rem;
				width: 1.5rem;
			}
		}
	}
	.page-content {
		margin-left: 55px;
		padding: 20px;
		.card-header,
		.card-body,
		.card {
			background-color: #fff;
		}
		.nav-link {
			border-radius: 5px 5px 0px 0px;
			padding: 10px 30px 10px 30px;
			&.active {
				background-color: $konark-primary-color;
				color: #fff;
			}
		}
		.nav-tabs-custom.border-right {
			border-right: 1px solid $konark-primary-color;
		}
		.css-1rhbuit-multiValue {
			background-color: #fff;
		}
		.css-2613qy-menu {
			background-color: #fff !important;
			border: 0.5px solid #c5c5c5;
			border-radius: 5px;
		}
		.grid-list-btn {
			font-size: 20px;
			color: $konark-primary-color;
			text-decoration: none;
		}
		.select-filters {
			width: 10.5rem;
			&:hover {
				color: $konark-primary-color;
			}
		}
		.table-special {
			thead {
				background-color: #f5f5f5;
				color: $konark-primary-color;
			}
			tr {
				border-bottom: 0.5px solid #c5c5c5;
			}
		}
		.logo {
			width: 2rem;
			height: 2rem;
			text-align: center;
		}
		.table-striped {
			tbody tr:nth-child(odd) {
				background-color: #f6fbff;
			}

			tbody tr:nth-child(even) {
				background-color: #ffffff;
			}
			tbody tr {
				.logo {
					@include avatar-log-or-text;
					color: #615d5d !important;
				}
			}
			tbody tr:nth-child(5n-1) {
				.logo {
					background: #b6d1e8;
				}
			}
			tbody tr:nth-child(5n-2) {
				.logo {
					background: #ecb907;
				}
			}
			tbody tr:nth-child(5n-3) {
				.logo {
					background: #a2dfce;
				}
			}
			tbody tr:nth-child(5n-4) {
				.logo {
					background: #ada8fa;
				}
			}
			tbody tr:nth-child(5n-5) {
				.logo {
					background: #ffb8b8;
				}
			}

			tbody tr {
				.logo {
					border: #28397f;
				}
			}
		}
	}
	#client-card {
		.client-card-col:nth-child(5n-1) {
			.logo {
				background: #b6d1e8;
			}
		}
		.client-card-col:nth-child(5n-2) {
			.logo {
				background: #ecb907;
			}
		}
		.client-card-col:nth-child(5n-3) {
			.logo {
				background: #a2dfce;
			}
		}
		.client-card-col:nth-child(5n-4) {
			.logo {
				background: #ada8fa;
			}
		}
		.client-card-col:nth-child(5n-5) {
			.logo {
				background: #ffb8b8;
			}
		}
	}
	.clients-card {
		width: 100%;
		height: 11.25rem;
		.card-body {
			height: 100%;
			border: 0.2px solid #0b83ed;
			border-radius: 10px;
			position: relative;
			padding: 1.8rem 1.5rem 1rem 1.5rem;
			&:hover {
				background: linear-gradient(180deg, rgba(40, 57, 127, 0.5) 0%, rgba(11, 131, 237, 0.5) 100%);
				box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
				border-radius: 10px;
				color: #ffffff;
			}
		}
		border: 0.2px solid #0b83ed;
		border-radius: 10px;
		position: relative;
		.logo {
			@include avatar-log-or-text;
			position: absolute;
			left: 50%;
			transform: translate(-50%, 0%);
			margin-top: -14px;
			width: 36px;
			height: 36px;
			text-align: center;
		}
		.edit-pencil-icon {
			top: 2px;
			right: 5px;
		}
		.card-checkbox {
			position: absolute;
			top: 3px;
			left: 5px;
		}
		.card-details {
			h4,
			.card-email,
			.card-country {
				width: 100%;
				text-align: center;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}
		}

		&:hover {
			background: linear-gradient(180deg, rgba(40, 57, 127, 0.5) 0%, rgba(11, 131, 237, 0.5) 100%);
			box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
			border-radius: 10px;
		}
	}
}
html[data-sidebar-open='true'] {
	#layout-wrapper {
		.page-content,
		header {
			margin-left: 141px;
		}
		.app-menu {
			ul {
				li {
					span {
						display: inline;
					}
				}
			}
		}
	}
}
@media (max-width: 576px) {
	html[data-sidebar-open='true'] {
		#layout-wrapper {
			header {
				margin-left: 0px;
			}
			.page-content {
				margin-left: 0px;
			}
			.app-menu {
				display: block;
				z-index: 100;
			}
		}
	}
	#layout-wrapper {
		.app-menu {
			display: none;
		}
		header {
			margin-left: 0px;
			.toggle {
				display: inline;
				margin-right: 10px;
				float: left;
				font-size: 25px;
			}
		}
		.page-content {
			margin-left: 0px;
		}
	}
}
.filter-modal-body {    
	border-radius: 10px;
	background-color: #fff;
	a {
		text-decoration: none;
	}
	button i {
		color: #d71509;
		font-size: 15px;
	}
	.flatpicker-border {
		border: 0.5px solid #c5c5c5;
		margin-bottom: 20px;
	} 
}
.listname {
	border-radius: 17px;
	padding: 7px 14px;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
}
.selected-filter-cross {
	color:red;
	margin-left: 3px;
}
